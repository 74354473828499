import Typography from '@mui/material/Typography';
import { type SuccessModalProps } from './SuccessModalProps';
import BaseModal from '../BaseModal';
import { useAppDispatch } from '@/store/hooks';
import { closeModal } from '@/store/reducers/modals/modalsSlice';
import { ModalType } from '@/types/modals';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

export const SuccessModal = ({ title, message, buttonText }: SuccessModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal({ type: ModalType.SuccessModal }));
  };

  const renderFooterCustom = () => (
    <FooterContainer>
      <Button sx={{ width: '80%' }} variant="contained" onClick={handleCloseModal}>
        {buttonText}
      </Button>
    </FooterContainer>
  );

  return (
    <BaseModal
      open
      onClose={handleCloseModal}
      renderFooterCustom={renderFooterCustom}
      maxWidth="xs"
    >
      <Container>
        <CheckCircleIcon sx={{ width: 50, height: 50 }} color="success" />
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{message}</Typography>
      </Container>
    </BaseModal>
  );
};
