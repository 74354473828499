import { MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, Divider, styled } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import { ExpandLess, ExpandMore, Logout } from '@mui/icons-material';
import { Can } from '@/contexts/accessControlContext';
import { PERMISSIONS_DICT } from '@/constants/permissions';
import { ROUTES } from '@/constants/routes';
import { useAppDispatch } from '@/store/hooks';
import { logout } from '@/store/reducers/session/actions';

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: 240,
  border: `1px solid ${theme.palette.divider}`,
  padding: 0,
}));

type MenuProps = {
  onMenuClick?: () => void;
  isCollapse?: boolean;
  isLogout?: boolean;
  isFullMenu?: boolean;
};

const Menu = ({ onMenuClick, isCollapse = true, isLogout, isFullMenu = true }: MenuProps) => {
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();

  const handleCollapse = () => {
    if (!isCollapse) return;

    setOpen(!open);
  };

  const handleLogout = () => {
    if (onMenuClick) onMenuClick();

    dispatch(logout());
  };

  const handleMenuClick = (e: MouseEvent<HTMLUListElement>) => {
    if ((e.target as HTMLElement).innerText === 'Management') return;

    if (onMenuClick) onMenuClick();
  };

  return (
    <StyledList onClick={handleMenuClick}>
      <ListItemButton component={NavLink} to={ROUTES.PROFILE_OWN}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemButton>
      <ListItemButton onClick={handleCollapse} sx={{ display: isFullMenu ? 'display' : 'none' }}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Management" sx={{ mr: 1 }} />
        {isCollapse && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse
        in={open}
        sx={{ display: isFullMenu ? 'display' : 'none' }}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <Can
            do={PERMISSIONS_DICT.ACCESS.USERS.LIST.modifier}
            on={PERMISSIONS_DICT.ACCESS.USERS.LIST.resource}
          >
            <ListItemButton sx={{ pl: 4 }} component={NavLink} to={ROUTES.USERS}>
              <ListItemText primary="Users" />
            </ListItemButton>
          </Can>
          <Can
            do={PERMISSIONS_DICT.ACCESS.ROLES.LIST.modifier}
            on={PERMISSIONS_DICT.ACCESS.ROLES.LIST.resource}
          >
            <ListItemButton sx={{ pl: 4 }} component={NavLink} to={ROUTES.ROLES}>
              <ListItemText primary="Roles" />
            </ListItemButton>
          </Can>
          <Can
            do={PERMISSIONS_DICT.ACCESS.PERMISSIONS.LIST.modifier}
            on={PERMISSIONS_DICT.ACCESS.PERMISSIONS.LIST.resource}
          >
            <ListItemButton sx={{ pl: 4 }} component={NavLink} to={ROUTES.PERMISSIONS}>
              <ListItemText primary="Permissions" />
            </ListItemButton>
          </Can>
          <Can
            do={PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.LIST.modifier}
            on={PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.LIST.resource}
          >
            <ListItemButton sx={{ pl: 4 }} component={NavLink} to={ROUTES.ACCESS_TOKENS}>
              <ListItemText primary="Access Tokens" />
            </ListItemButton>
          </Can>
          <Can
            do={PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.LIST.modifier}
            on={PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.LIST.resource}
          >
            <ListItemButton sx={{ pl: 4 }} component={NavLink} to={ROUTES.ACCESS_REQUESTS}>
              <ListItemText primary="Access Requests" />
            </ListItemButton>
          </Can>
        </List>
      </Collapse>
      {isLogout && (
        <>
          <Divider />
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <Logout color="action" sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ mr: 1 }} />
          </ListItemButton>
        </>
      )}
    </StyledList>
  );
};

export default Menu;
