const palette = {
  primary: {
    main: '#79C91F',
    dark: '#66b014',
    light: '#8cef1f',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#666666',
    dark: '#454444',
    light: '#8a8888',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#79C91F',
    dark: '#66b014',
    light: '#8cef1f',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    contrastText: '#25272D',
  },
  warning: {
    main: '#ED6C02',
    dark: '#cb5c02',
    light: '#FF9800',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#215EB2',
    dark: '#1a4a8d',
    light: '#2771d8',
    contrastText: '#25272D',
  },
  text: {
    primary: '#25272D',
    secondary: '#666666',
    disabled: '#A0A0A0',
  },
  background: {
    paper: '#edecec',
    default: '#FFFFFF',
  },
  backgroundHeader: {
    main: '#25272D',
  },
  border: {
    main: '#E1E1E1',
    dark: '#A0A0A0',
  },
};

export default palette;
