export const TICKER_PERMISSIONS_DICT = {
  LIST: {
    title: 'TICKERS_LIST',
    description: 'Controls list tickers use-case',
    resource: 'TICKERS',
    modifier: 'LIST',
  },
};

export const TICKER_PERMISSIONS_LIST = Object.values(TICKER_PERMISSIONS_DICT);
