import SessionAPI from './Session';
import ProfileAPI from './Profile';
import RolesAPI from './Roles';
import UsersAPI from './Users';
import RequestAccess from './RequestAccess';
import ApiClientFactory from './apiClientFactory';
import AccessTokens from './AccessTokens';
import { selectCSRFToken, authenticationErrorHandler } from '@/api/utils/apiUtils';
import PermissionsAPI from './Permissions';
import config from '@/config';

const baseURL = config.apiUrl + config.apiPrefix;

export const initApi = () => {
  const apiClient = new ApiClientFactory(
    baseURL,
    selectCSRFToken,
    authenticationErrorHandler,
  ).create();

  return {
    apiClient,
    session: new SessionAPI({ apiClient }),
    profile: new ProfileAPI({ apiClient }),
    roles: new RolesAPI({ apiClient }),
    permissions: new PermissionsAPI({ apiClient }),
    users: new UsersAPI({ apiClient }),
    requestAccess: new RequestAccess({ apiClient }),
    accessTokens: new AccessTokens({ apiClient }),
  };
};

export default initApi();
