import { ACCESS_TOKEN_PERMISSIONS_DICT, ACCESS_TOKEN_PERMISSIONS_LIST } from './access-tokens';
import { PERMISSION_PERMISSIONS_DICT, PERMISSION_PERMISSIONS_LIST } from './permissions';
import { ROLE_PERMISSIONS_DICT, ROLE_PERMISSIONS_LIST } from './roles';
import { SESSION_PERMISSIONS_DICT, SESSION_PERMISSIONS_LIST } from './sessions';
import { USER_PERMISSIONS_DICT, USER_PERMISSIONS_LIST } from './users';
import { REQUEST_ACCESS_PERMISSIONS_DICT, REQUEST_ACCESS_PERMISSIONS_LIST } from './request-access';

export const ACCESS_PERMISSIONS_DICT = {
  ACCESS_TOKENS: ACCESS_TOKEN_PERMISSIONS_DICT,
  PERMISSIONS: PERMISSION_PERMISSIONS_DICT,
  ROLES: ROLE_PERMISSIONS_DICT,
  SESSIONS: SESSION_PERMISSIONS_DICT,
  USERS: USER_PERMISSIONS_DICT,
  REQUEST_ACCESS: REQUEST_ACCESS_PERMISSIONS_DICT,
};

export const ACCESS_PERMISSIONS_LIST = [
  ...ACCESS_TOKEN_PERMISSIONS_LIST,
  ...PERMISSION_PERMISSIONS_LIST,
  ...ROLE_PERMISSIONS_LIST,
  ...SESSION_PERMISSIONS_LIST,
  ...USER_PERMISSIONS_LIST,
  ...REQUEST_ACCESS_PERMISSIONS_LIST,
];
