import { EXCEPTION_CODES } from '@/constants/errors/codes';

export class ApiException extends Error {
  constructor(
    message: string,
    public code: EXCEPTION_CODES = EXCEPTION_CODES.SERVER_ERROR,
    public fields?: Record<string, string | string[]>,
    public context?: object,
  ) {
    super(message);
  }
}
