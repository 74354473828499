export const EXCHANGE_PERMISSIONS_DICT = {
  LIST: {
    title: 'EXCHANGES_LIST',
    description: 'Controls list exchange use-case',
    resource: 'EXCHANGES',
    modifier: 'LIST',
  },
  UPDATE: {
    title: 'EXCHANGES_UPDATE',
    description: 'Controls update exchange use-case',
    resource: 'EXCHANGES',
    modifier: 'UPDATE',
  },
};

export const EXCHANGE_PERMISSIONS_LIST = Object.values(EXCHANGE_PERMISSIONS_DICT);
