import { createSlice } from '@reduxjs/toolkit';
import { TotalListMeta } from '@/types/api';
import { AccessToken, AccessTokenMeta } from '@/types/accessToken';
import { deleteAccessToken, getAccessToken, listAccessTokens, updateAccessToken } from './actions';

type InitialStateSessionProps = {
  accessTokens: AccessToken[];
  selectedTokenMeta: AccessTokenMeta | null;
  meta: TotalListMeta | null;
  isLoading: boolean;
};

const initialState: InitialStateSessionProps = {
  accessTokens: [],
  selectedTokenMeta: null,
  meta: null,
  isLoading: false,
};

export const accessTokensSlice = createSlice({
  name: 'accessTokens',
  initialState,
  reducers: {
    clearAccessTokensState: () => {
      return initialState;
    },
    clearSelectedAccessTokenMeta: (state) => {
      state.selectedTokenMeta = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listAccessTokens.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listAccessTokens.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accessTokens = action.payload.accessTokens;
      state.meta = action.payload.meta;
    });

    builder.addCase(updateAccessToken.fulfilled, (state, action) => {
      const currentIndex = state.accessTokens.findIndex((item) => item.id === action.payload.id);

      if (currentIndex !== -1) {
        state.accessTokens[currentIndex] = action.payload;
      }
    });

    builder.addCase(deleteAccessToken.fulfilled, (state, action) => {
      state.accessTokens = state.accessTokens.filter((item) => item.id !== action.payload);
    });

    builder.addCase(getAccessToken.fulfilled, (state, action) => {
      state.selectedTokenMeta = action.payload.meta;
    });
  },
  selectors: {
    selectAccessTokens: (state) => state.accessTokens,
    selectAccessTokensListMeta: (state) => state.meta,
    selectSelectedTokenMeta: (state) => state.selectedTokenMeta,
    selectAccessTokensListIsLoading: (state) => state.isLoading,
  },
});

export const { clearAccessTokensState, clearSelectedAccessTokenMeta } = accessTokensSlice.actions;
export const {
  selectAccessTokens,
  selectAccessTokensListMeta,
  selectAccessTokensListIsLoading,
  selectSelectedTokenMeta,
} = accessTokensSlice.selectors;
