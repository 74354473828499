import { selectError } from '@/store/reducers/errors/errorsSlice';
import { useAppSelector } from '@/store/hooks';
import { UIErrorTypes } from '@/types/errors';

export function useError(type: UIErrorTypes) {
  const error = useAppSelector(selectError);

  if (error?.type === type) {
    return error;
  }

  return null;
}
