import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { RequestAccessForm } from '@/components/forms/RequestAccessForm';
import SuccessBlock from '@/components/SuccessBlock';

const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(2, 0, 2, 0),
}));

export default function RequestAccessPage() {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Container container>
      <Grid item xs={11} md={6} lg={4}>
        {isSuccess ? (
          <SuccessBlock title="Request Access" message="Your request has been sent." />
        ) : (
          <RequestAccessForm onSuccess={() => setIsSuccess(true)} />
        )}
      </Grid>
    </Container>
  );
}
