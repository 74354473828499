import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api/apiSingleton';
import { PermissionsListParams, PermissionsModifyParams } from '@/api/Permissions';
import { withComponentErrorHandling, withDefaultErrorHandling } from '@/store/utils';

export const listPermissions = createAsyncThunk(
  'permissions/listPermissions',
  withDefaultErrorHandling(async (params: PermissionsListParams = {}) => {
    const response = await api.permissions.list(params);

    return { permissions: response.data, meta: response.meta };
  }),
);

export const getPermission = createAsyncThunk(
  'permissions/getPermission',
  withDefaultErrorHandling(async (permissionId: number) => {
    const response = await api.permissions.get(permissionId);

    return { permission: response.data, meta: response.meta };
  }),
);

export const createPermission = createAsyncThunk(
  'permissions/createPermission',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: PermissionsModifyParams) => {
      const response = await api.permissions.create(payload);

      return response.data;
    }),
  ),
);

export const deletePermission = createAsyncThunk(
  'permissions/deletePermission',
  withDefaultErrorHandling(async (permissionId: number) => {
    await api.permissions.delete(permissionId);

    return permissionId;
  }),
);

export const updatePermission = createAsyncThunk(
  'permissions/updatePermission',
  withDefaultErrorHandling(
    withComponentErrorHandling(
      async (payload: { formData: PermissionsModifyParams; permissionId: number }) => {
        const response = await api.permissions.update(payload);

        return response.data;
      },
    ),
  ),
);
