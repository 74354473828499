import { createSlice } from '@reduxjs/toolkit';
import { deleteRole, listRoles, updateRole } from './actions';
import { TotalListMeta } from '@/types/api';
import { Role } from '@/types/role';

type InitialStateSessionProps = {
  roles: Role[];
  meta: TotalListMeta | null;
  isLoading: boolean;
};

const initialState: InitialStateSessionProps = {
  roles: [],
  meta: null,
  isLoading: false,
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    clearRoles: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listRoles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.roles = action.payload.roles;
      state.meta = action.payload.meta;
    });

    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.roles = state.roles.filter((item) => item.id !== action.payload);
    });

    builder.addCase(updateRole.fulfilled, (state, action) => {
      const currentRoleIndex = state.roles.findIndex((item) => item.id === action.payload.id);

      if (currentRoleIndex !== -1) {
        state.roles[currentRoleIndex] = action.payload;
      }
    });
  },
  selectors: {
    selectRoles: (state) => state.roles,
    selectRolesListMeta: (state) => state.meta,
    selectRolesListIsLoading: (state) => state.isLoading,
  },
});

export const { clearRoles } = rolesSlice.actions;
export const { selectRoles, selectRolesListMeta, selectRolesListIsLoading } = rolesSlice.selectors;
