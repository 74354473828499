import { UserStatus } from '@/types/user';
import { Chip, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type Colors = 'success' | 'error' | 'warning';

const STATUS_COLOR: Record<UserStatus, Colors> = {
  [UserStatus.ACTIVE]: 'success',
  [UserStatus.BLOCKED]: 'error',
  [UserStatus.PENDING]: 'warning',
};

const StyledChip = styled(Chip)(({ theme, color }: { theme?: Theme; color: Colors }) => ({
  color: theme?.palette[color].main,
  background: theme?.palette.background.paper,
  borderRadius: '5px',
}));

type Props = {
  status: keyof typeof UserStatus;
};

export const Status = ({ status }: Props) => {
  return <StyledChip label={status} color={STATUS_COLOR[status]} />;
};
