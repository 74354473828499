import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default function PageNotFound() {
  return (
    <Container>
      <Typography variant="h3">404 Page not found</Typography>
    </Container>
  );
}
