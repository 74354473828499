import Base from './Base';
import { User, ProfileMeta } from '@/types/user';
import { ApiResponse, ListParams, TotalListMeta } from '@/types/api';

export type UsersFilterParams = {
  role?: string;
  status?: 'ACTIVE' | 'BLOCKED' | 'PENDING';
};

export type UsersListParams = ListParams & UsersFilterParams;

class Users extends Base {
  async list(params: UsersListParams) {
    const response = await this.apiClient.get<ApiResponse<User[], TotalListMeta>>('/access/users', {
      params,
    });

    return response.data;
  }

  async get(userId: number) {
    const response = await this.apiClient.get<ApiResponse<User, ProfileMeta>>(
      `/access/users/${userId}`,
    );

    return response.data;
  }

  async create(formData: FormData) {
    const response = await this.apiClient.postForm<ApiResponse<User, null>>(
      '/access/users',
      formData,
    );

    return response.data;
  }

  async delete(userId: number) {
    await this.apiClient.delete(`/access/users/${userId}`);
  }

  async update({ formData, userId }: { formData: FormData; userId: number }) {
    const response = await this.apiClient.patchForm<ApiResponse<User, ProfileMeta>>(
      `/access/users/${userId}`,
      formData,
    );

    return response.data;
  }

  autocompleteUsersList = async (params: UsersListParams) => {
    const response = await this.list(params);

    return {
      data: response.data.map((user) => ({
        id: user.id,
        title: `${user.firstName} ${user.lastName} (${user.email})`,
      })),
      meta: response.meta,
    };
  };
}

export default Users;
