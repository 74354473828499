import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api/apiSingleton';
import { UsersListParams } from '@/api/Users';
import { withComponentErrorHandling, withDefaultErrorHandling } from '@/store/utils';

export const listUsers = createAsyncThunk(
  'users/listUsers',
  withDefaultErrorHandling(async (params: UsersListParams = {}) => {
    const response = await api.users.list(params);

    return { users: response.data, meta: response.meta };
  }),
);

export const getUser = createAsyncThunk(
  'users/getUser',
  withDefaultErrorHandling(async (userId: number) => {
    const response = await api.users.get(userId);

    return { user: response.data, meta: response.meta };
  }),
);

export const createUser = createAsyncThunk(
  'users/createUser',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: FormData) => {
      const response = await api.users.create(payload);

      return response.data;
    }),
  ),
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  withDefaultErrorHandling(async (userId: number) => {
    await api.users.delete(userId);

    return userId;
  }),
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: { formData: FormData; userId: number }) => {
      const response = await api.users.update(payload);

      return response.data;
    }),
  ),
);
