import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Badge } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AvatarCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 116,
  height: 116,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: 58,
}));

const AddPhotoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 35,
  height: 35,
  border: `1px solid ${theme.palette.border.main}`,
  background: theme.palette.background.default,
  borderRadius: 20,
}));

type Props = {
  id: string;
  avatarUrl?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function UploadAvatar({ id, avatarUrl, onChange }: Props) {
  return (
    <ButtonBase component="label" disableRipple>
      <AvatarCircle>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <AddPhotoContainer>
              <AddAPhotoIcon color="secondary" />
            </AddPhotoContainer>
          }
        >
          <Avatar id={id} src={avatarUrl} sx={{ width: 106, height: 106 }} />
        </Badge>
      </AvatarCircle>
      <VisuallyHiddenInput
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={onChange}
      />
    </ButtonBase>
  );
}
