export const STATIC_API_PERMISSIONS_DICT = {
  READ_CRYPTO_TOKENS: {
    title: 'STATIC_API_READ_CRYPTO_TOKENS',
    description: 'Controls read crypto tokens files use case for STATIC API',
    resource: 'STATIC_API',
    modifier: 'READ_CRYPTO_TOKENS',
  },
  READ_CRYPTO_TOKEN_MARKETS: {
    title: 'STATIC_API_READ_CRYPTO_TOKEN_MARKETS',
    description: 'Controls read crypto token markets files use case for STATIC API',
    resource: 'STATIC_API',
    modifier: 'READ_CRYPTO_TOKEN_MARKETS',
  },
  READ_EXCHANGES: {
    title: 'STATIC_API_READ_EXCHANGES',
    description: 'Controls read exchange files use case for STATIC API',
    resource: 'STATIC_API',
    modifier: 'READ_EXCHANGES',
  },
  READ_USER_AVATARS: {
    title: 'STATIC_API_READ_USER_AVATARS',
    description: 'Controls read user avatar files use case for STATIC API',
    resource: 'STATIC_API',
    modifier: 'READ_USER_AVATARS',
  },
};

export const STATIC_API_PERMISSIONS_LIST = Object.values(STATIC_API_PERMISSIONS_DICT);
