import LocalizationProvider from './LocalizationProvider';
import StoreProvider from './StoreProvider';
import ThemeProvider from './ThemeProvider';

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <StoreProvider>
      <LocalizationProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </LocalizationProvider>
    </StoreProvider>
  );
}
