import { useCallback, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ErrorSchema, RJSFSchema } from '@rjsf/utils';
import { IChangeEvent } from '@rjsf/core';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { BaseForm } from './BaseForm';
import { useAppDispatch } from '@/store/hooks';
import { resetPassword } from '@/store/reducers/session/actions';
import { ROUTES } from '@/constants/routes';
import { clearErrors, formatErrors } from './utils/rjsfErrors';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const ErrorMessageContainer = styled('div')(({ theme }) => ({
  height: 18,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export type LoginFromState = {
  email: string;
  password: string;
};

const schema: RJSFSchema = {
  type: 'object',
  properties: {
    password: {
      type: 'string',
      title: 'Password',
    },
    confirmPassword: {
      type: 'string',
      title: 'Confirm Password',
    },
  },
  required: ['password', 'confirmPassword'],
};

const uiSchema = {
  password: {
    'ui:widget': 'PasswordInputWidget',
  },
  confirmPassword: {
    'ui:widget': 'PasswordInputWidget',
  },
};

type Props = {
  onSuccess: () => void;
};

export function ResetPasswordForm({ onSuccess }: Props) {
  const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState<Record<string, ErrorSchema>>({});
  const dispatch = useAppDispatch();
  const { token } = useParams();

  const handleOnSubmit = useCallback(async ({ formData }: IChangeEvent) => {
    try {
      setFormData(formData);
      await dispatch(resetPassword({ ...formData, token })).unwrap();

      onSuccess();
    } catch (error: unknown) {
      console.error(error);

      if (error instanceof Object) {
        const errors = formatErrors(error as Record<string, string>);

        setFormErrors(errors);
      } else {
        setError(error as string);
      }
    }
  }, []);

  const handleChange = useCallback(
    (event: IChangeEvent, fieldId?: string) => {
      setFormData(event.formData);

      const newErrors = clearErrors(formErrors, fieldId);

      setFormErrors(newErrors);
    },
    [formErrors],
  );

  return (
    <FormContainer>
      <Typography variant="h4">Reset Password</Typography>
      <Typography variant="subtitle1">Enter your new password.</Typography>
      <BaseForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        extraErrors={formErrors}
        onSubmit={handleOnSubmit}
        onChange={handleChange}
      >
        <ErrorMessageContainer>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </ErrorMessageContainer>
        <Button type="submit" variant="contained" fullWidth>
          RESET PASSWORD
        </Button>
      </BaseForm>
      <LinksContainer>
        <Link to={ROUTES.LOGIN} component={NavLink} underline="always">
          Back to Login
        </Link>
      </LinksContainer>
    </FormContainer>
  );
}
