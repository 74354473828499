import { ErrorSchema, RJSFSchema } from '@rjsf/utils';
import Button from '@mui/material/Button';
import BaseModal from '../BaseModal';
import { RejectAccessRequestModalProps } from './RejectAccessRequestModalProps';
import { BaseForm } from '@/components/forms/BaseForm';
import { useCallback, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { IChangeEvent } from '@rjsf/core';
import { closeModal } from '@/store/reducers/modals/modalsSlice';
import { ModalType } from '@/types/modals';
import { clearErrors, formatErrors } from '@/components/forms/utils/rjsfErrors';

const schema: RJSFSchema = {
  type: 'object',
  properties: {
    reason: {
      type: 'string',
      title: 'Reason',
    },
  },
};

const uiSchema = {
  reason: {
    'ui:widget': 'textarea',
  },
};

export const RejectAccessRequestModal = ({
  title,
  buttonText,
  onSubmit,
}: RejectAccessRequestModalProps) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState<Record<string, ErrorSchema>>({});
  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal({ type: ModalType.RejectAccessRequestModal }));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async ({ formData }: IChangeEvent) => {
      try {
        await onSubmit(formData.reason);

        handleCloseModal();
      } catch (error: unknown) {
        console.error(error);

        const errors = formatErrors(error as Record<string, string>);

        setErrors(errors);
      }
    },
    [onSubmit, handleCloseModal],
  );

  const handleChange = useCallback(
    (event: IChangeEvent, fieldId?: string) => {
      setFormData(event.formData);

      const newErrors = clearErrors(errors, fieldId);

      setErrors(newErrors);
    },
    [errors],
  );

  return (
    <BaseModal open title={title} onClose={handleCloseModal} renderFooterCustom={() => null}>
      <BaseForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={handleSubmit}
        onChange={handleChange}
        extraErrors={errors}
      >
        <Button
          type="submit"
          variant="contained"
          sx={(theme) => ({ mt: theme.spacing(2) })}
          fullWidth
        >
          {buttonText}
        </Button>
      </BaseForm>
    </BaseModal>
  );
};
