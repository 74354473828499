import { useCallback, useMemo, useState } from 'react';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useAbility } from '@casl/react';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Edit from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Table, { TableState } from '@/components/table/Table';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { ListParams } from '@/types/api';
import { useModal } from '@/hooks/useModal';
import { ModalType } from '@/types/modals';
import { AbilityContext } from '@/contexts/accessControlContext';
import {
  createAccessToken,
  deleteAccessToken,
  getAccessToken,
  listAccessTokens,
  updateAccessToken,
} from '@/store/reducers/accessTokens/actions';
import { formatDate } from '@/utils/date';
import {
  clearSelectedAccessTokenMeta,
  selectAccessTokens,
  selectAccessTokensListIsLoading,
  selectAccessTokensListMeta,
  selectSelectedTokenMeta,
} from '@/store/reducers/accessTokens/accessTokensSlice';
import { AccessToken } from '@/types/accessToken';
import { AccessTokenFormData } from '@/components/modals/AccessTokenModal';
import { RateLimiting } from '@/components/RateLimiting';
import SensitiveText from '@/components/SensitiveText';
import { PERMISSIONS_DICT } from '@/constants/permissions';

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function AccessTokensPage() {
  const accessTokens = useAppSelector(selectAccessTokens);
  const accessTokensListMeta = useAppSelector(selectAccessTokensListMeta);
  const isLoading = useAppSelector(selectAccessTokensListIsLoading);
  const selectedTokenMeta = useAppSelector(selectSelectedTokenMeta);
  const ability = useAbility(AbilityContext);
  const dispatch = useAppDispatch();
  const [paramsState, setParamsState] = useState<ListParams>({});

  const { openModal: openAccessTokenModal } = useModal(ModalType.AccessTokenModal);
  const { openModal: openSuccessModal } = useModal(ModalType.SuccessModal);
  const { openModal: openConfirmationModal } = useModal(ModalType.ConfirmationModal);

  const handleOpenCreateAccessToken = useCallback(() => {
    openAccessTokenModal({
      title: 'Create Access Token',
      buttonText: 'CREATE ACCESS TOKEN',
      onSubmit: async (formData: AccessTokenFormData) => {
        await dispatch(
          createAccessToken({
            ...formData,
            roleId: formData.roleId.id,
            assigneeId: formData.assigneeId?.id,
          }),
        ).unwrap();

        openSuccessModal({
          title: 'Access Token Successfully Created',
          buttonText: 'OK',
        });
        dispatch(listAccessTokens(paramsState));
      },
    });
  }, [paramsState, dispatch, openAccessTokenModal, openSuccessModal]);

  const handleEdit = useCallback(
    (item: AccessToken) => {
      openAccessTokenModal({
        title: 'Edit Access Token',
        buttonText: 'UPDATE ACCESS TOKEN',
        formData: {
          title: item.title,
          description: item.description || undefined,
          roleId: item.role,
          assigneeId: {
            id: item.assignee.id,
            title: `${item.assignee.firstName} ${item.assignee.lastName} (${item.assignee.email})`,
          },
          expiresAt: item.expiresAt,
        },
        onSubmit: async (formData: AccessTokenFormData) => {
          await dispatch(
            updateAccessToken({
              formData: {
                ...formData,
                roleId: formData.roleId.id,
                assigneeId: formData.assigneeId?.id,
              },
              tokenId: item.id,
            }),
          ).unwrap();

          openSuccessModal({
            title: 'Access Token Successfully Updated',
            buttonText: 'OK',
          });
        },
      });
    },
    [dispatch, openSuccessModal, openAccessTokenModal],
  );

  const handleDelete = useCallback(
    (tokenId: string) => {
      openConfirmationModal({
        title: 'Delete Access Token',
        buttonText: 'DELETE',
        message: 'Are you sure you want to delete the access token?',
        onSubmit: async () => {
          await dispatch(deleteAccessToken(tokenId)).unwrap();
        },
      });
    },
    [dispatch, openConfirmationModal],
  );

  const handleOnChange = useCallback(
    (state: TableState) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filters = state.filters.reduce<Record<string, string>>((acc, curr: any) => {
        acc[curr.id] = curr.value;

        return acc;
      }, {});

      const params: ListParams = {
        ...filters,
        limit: state.pagination.pageSize,
        offset: state.pagination.pageSize * state.pagination.pageIndex,
        sortBy: state.sorting?.[0]?.id,
        orderBy: state.sorting?.[0]?.desc ? 'DESC' : 'ASC',
        search: state.searchFilter || null,
      };

      setParamsState(params);

      dispatch(listAccessTokens(params));
    },
    [dispatch],
  );

  const columns = useMemo<MRT_ColumnDef<AccessToken>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        grow: true,
        size: 40,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'role',
        header: 'Role',
        size: 40,
        grow: true,
        enableSorting: false,
        accessorFn: (row) => row.role.title,
      },
      {
        accessorKey: 'assignee',
        header: 'Assignee',
        size: 40,
        grow: true,
        enableSorting: false,
        accessorFn: (row) => row.assignee.email,
      },
      {
        accessorKey: 'expiresAt',
        header: 'Expires At',
        size: 40,
        grow: true,
        enableColumnFilter: false,
        accessorFn: (row) => formatDate(row.expiresAt),
      },
    ],
    [],
  );

  const toolbarActions = useMemo(
    () => [
      {
        name: 'NEW ACCESS TOKEN',
        action: handleOpenCreateAccessToken,
        icon: <Add />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.CREATE.modifier,
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.CREATE.resource,
        ),
      },
    ],
    [handleOpenCreateAccessToken, ability],
  );

  const rowActions = useMemo(
    () => [
      {
        name: 'Edit',
        action: (item: AccessToken) => handleEdit(item),
        icon: <Edit />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.UPDATE.modifier,
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.UPDATE.resource,
        ),
      },
      {
        name: 'Delete',
        action: (item: AccessToken) => handleDelete(item.id),
        icon: <Delete />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.DELETE.modifier,
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.DELETE.resource,
        ),
      },
    ],
    [ability, handleEdit, handleDelete],
  );

  const renderDetailPanel = useMemo(
    () =>
      ({ row }: { row: MRT_Row<AccessToken> }) => {
        const json = {
          ...row.original,
          id: undefined,
        };

        return (
          <>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">Access Token</Typography>
              <SensitiveText>{row.original.id}</SensitiveText>
            </Box>
            <RateLimiting rateLimit={selectedTokenMeta?.rateLimit} />
            <SyntaxHighlighter
              language="json"
              style={atomOneLight}
              wrapLongLines
              customStyle={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {JSON.stringify(json, null, 4)}
            </SyntaxHighlighter>
          </>
        );
      },
    [selectedTokenMeta],
  );

  const options = useMemo(() => {
    return {
      data: accessTokens,
      columns: columns,
      rowCount: accessTokensListMeta?.totalCount,
      enableExpandAll: false,
      renderDetailPanel,
      muiExpandButtonProps: ({ row }: { row: MRT_Row<AccessToken> }) => ({
        onClick: () => {
          if (!row.getIsExpanded()) {
            dispatch(getAccessToken(row.original.id));
          } else {
            dispatch(clearSelectedAccessTokenMeta());
          }
        },
      }),
    };
  }, [dispatch, renderDetailPanel, accessTokens, columns, accessTokensListMeta?.totalCount]);

  return (
    <Box>
      <Title variant="h4">Access Tokens Management</Title>
      <Table
        options={options}
        onChange={handleOnChange}
        rowActions={rowActions}
        isLoading={isLoading}
        toolbarActions={toolbarActions}
      />
    </Box>
  );
}
