import type {
  UserModalProps,
  RoleModalProps,
  PermissionModalProps,
  ErrorModalProps,
  SelectRoleModalProps,
  RejectAccessRequestModalProps,
  AccessTokenModalProps,
  ConfirmationModalProps,
} from '@/components/modals';
import { SuccessModalProps } from '@/components/modals/SuccessModal';

export enum ModalType {
  UserModal = 'UserModal',
  RoleModal = 'RoleModal',
  PermissionModal = 'PermissionModal',
  ErrorModal = 'ErrorModal',
  ConfirmationModal = 'ConfirmationModal',
  SelectRoleModal = 'SelectRoleModal',
  RejectAccessRequestModal = 'RejectAccessRequestModal',
  AccessTokenModal = 'AccessTokenModal',
  SuccessModal = 'SuccessModal',
}

type ModalsPropsMap = {
  [ModalType.UserModal]: UserModalProps;
  [ModalType.RoleModal]: RoleModalProps;
  [ModalType.PermissionModal]: PermissionModalProps;
  [ModalType.ErrorModal]: ErrorModalProps;
  [ModalType.ConfirmationModal]: ConfirmationModalProps;
  [ModalType.SelectRoleModal]: SelectRoleModalProps;
  [ModalType.RejectAccessRequestModal]: RejectAccessRequestModalProps;
  [ModalType.AccessTokenModal]: AccessTokenModalProps;
  [ModalType.SuccessModal]: SuccessModalProps;
};

export type ModalProps<T> = T extends ModalType ? ModalsPropsMap[T] : never;

export interface Modal {
  type: ModalType;
  componentProps: ModalProps<ModalType>;
}
