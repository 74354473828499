import { createSlice } from '@reduxjs/toolkit';
import { Modal } from '@/types/modals';

type InitialStateModalsProps = {
  activeModals: Modal[];
};

const initialState: InitialStateModalsProps = {
  activeModals: [],
};

export const modalsSlice = createSlice({
  name: 'modals',
  reducerPath: 'modals',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const isModal = state.activeModals.find((item) => item.type === action.payload.type);

      if (!isModal) {
        state.activeModals.push(action.payload);
      }
    },
    closeModal: (state, action) => {
      state.activeModals = state.activeModals.filter((modal) => modal.type !== action.payload.type);
    },
    updateModal: (state, action) => {
      state.activeModals = state.activeModals.map((dialog) => {
        if (dialog.type === action.payload.type) {
          return {
            ...dialog,
            componentProps: {
              ...dialog.componentProps,
              ...action.payload.componentProps,
            },
          };
        }

        return dialog;
      });
    },
    clearModalState: (state) => {
      state.activeModals = [];
    },
  },
  selectors: {
    selectActiveModals: (state) => state.activeModals,
  },
});

export const { openModal, closeModal, updateModal, clearModalState } = modalsSlice.actions;
export const { selectActiveModals } = modalsSlice.selectors;
