import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.backgroundHeader.main,
  color: '#fff',
  height: 64,
  padding: theme.spacing(1, 2, 1, 2),
  zIndex: 1201,
}));

const Footer = () => {
  return (
    <FooterContainer>
      <Typography variant="caption">© 2024 Wolfram Blockchain Labs. A Wolfram Company.</Typography>
    </FooterContainer>
  );
};

export default Footer;
