import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RJSFSchema } from '@rjsf/utils';
import { IChangeEvent } from '@rjsf/core';
import { Button, Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BaseForm } from './BaseForm';
import { useAppDispatch } from '@/store/hooks';
import { login } from '@/store/reducers/session/actions';
import { ROUTES } from '@/constants/routes';
import { NavLink } from 'react-router-dom';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const ErrorMessageContainer = styled('div')(({ theme }) => ({
  height: 18,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export type LoginFromState = {
  email: string;
  password: string;
};

const schema: RJSFSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      title: 'Email',
    },
    password: {
      type: 'string',
      title: 'Password',
    },
  },
};

const uiSchema = {
  password: {
    'ui:widget': 'PasswordInputWidget',
  },
};

export function LoginForm() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnSubmit = async ({ formData }: IChangeEvent) => {
    try {
      setFormData(formData);

      await dispatch(login(formData)).unwrap();

      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        navigate(ROUTES.PROFILE_OWN);
      }
    } catch (error: unknown) {
      setError(error as string);
    }
  };

  return (
    <FormContainer>
      <Typography variant="h4">Log In</Typography>
      <Typography variant="subtitle1">Welcome Back! Please log in to continue.</Typography>
      <BaseForm schema={schema} uiSchema={uiSchema} onSubmit={handleOnSubmit} formData={formData}>
        <ErrorMessageContainer>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </ErrorMessageContainer>
        <Button type="submit" variant="contained" fullWidth>
          LOGIN
        </Button>
      </BaseForm>
      <LinksContainer>
        <Link to={ROUTES.REQUEST_ACCESS} component={NavLink} underline="always">
          Request access
        </Link>
        <Link to={ROUTES.REQUEST_PASSWORD_RESET} component={NavLink} underline="always">
          Forgot password
        </Link>
      </LinksContainer>
    </FormContainer>
  );
}
