import { useCallback, useMemo } from 'react';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { useAbility } from '@casl/react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Delete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Table, { TableState } from '@/components/table/Table';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { ListParams } from '@/types/api';
import { useModal } from '@/hooks/useModal';
import { ModalType } from '@/types/modals';
import { AbilityContext } from '@/contexts/accessControlContext';
import {
  acceptAccessRequest,
  listAccessRequests,
  rejectAccessRequest,
} from '@/store/reducers/accessRequests/actions';
import { formatDate } from '@/utils/date';
import {
  selectAccessRequests,
  selectAccessRequestsListIsLoading,
  selectAccessRequestsListMeta,
} from '@/store/reducers/accessRequests/accessRequestsSlice';
import { AccessRequest } from '@/types/accessRequest';
import { PERMISSIONS_DICT } from '@/constants/permissions';

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function AccessRequestsPage() {
  const accessRequests = useAppSelector(selectAccessRequests);
  const accessRequestsListMeta = useAppSelector(selectAccessRequestsListMeta);
  const isLoading = useAppSelector(selectAccessRequestsListIsLoading);
  const ability = useAbility(AbilityContext);
  const dispatch = useAppDispatch();

  const { openModal: openSelectRoleModal } = useModal(ModalType.SelectRoleModal);
  const { openModal: openRejectAccessRequestModal } = useModal(ModalType.RejectAccessRequestModal);
  const { openModal: openSuccessModal } = useModal(ModalType.SuccessModal);

  const handleAcceptRequest = useCallback(
    (item: AccessRequest) => {
      openSelectRoleModal({
        title: 'Select Role',
        buttonText: 'ACCEPT REQUEST',
        onSubmit: async (roleId: number) => {
          await dispatch(acceptAccessRequest({ requestId: item.id, roleId })).unwrap();

          openSuccessModal({
            title: 'Request was accepted',
            buttonText: 'OK',
          });
        },
      });
    },
    [dispatch, openSelectRoleModal, openSuccessModal],
  );

  const handleRejectRequest = useCallback(
    (requestId: number) => {
      openRejectAccessRequestModal({
        title: 'Reject request',
        buttonText: 'REJECT REQUEST',
        onSubmit: async (reason: string) => {
          await dispatch(rejectAccessRequest({ requestId, reason })).unwrap();

          openSuccessModal({
            title: 'Request was rejected',
            buttonText: 'OK',
          });
        },
      });
    },
    [dispatch, openRejectAccessRequestModal, openSuccessModal],
  );

  const handleRejectSelectedRequest = useCallback(
    (indexes: string[], clearSelection: () => void) => {
      openRejectAccessRequestModal({
        title: 'Reject requests',
        buttonText: 'REJECT REQUESTS',
        onSubmit: async (reason: string) => {
          const promises = indexes.map((index) => {
            const requestId = accessRequests[+index].id;

            return dispatch(rejectAccessRequest({ requestId, reason })).unwrap();
          });

          await Promise.all(promises);

          clearSelection();

          openSuccessModal({
            title: 'Requests were rejected',
            buttonText: 'OK',
          });
        },
      });
    },
    [dispatch, openRejectAccessRequestModal, openSuccessModal, accessRequests],
  );

  const handleOnChange = useCallback(
    (state: TableState) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filters = state.filters.reduce<Record<string, string>>((acc, curr: any) => {
        acc[curr.id] = curr.value;

        return acc;
      }, {});

      const params: ListParams = {
        ...filters,
        limit: state.pagination.pageSize,
        offset: state.pagination.pageSize * state.pagination.pageIndex,
        sortBy: state.sorting?.[0]?.id,
        orderBy: state.sorting?.[0]?.desc ? 'DESC' : 'ASC',
        search: state.searchFilter || null,
      };

      dispatch(listAccessRequests(params));
    },
    [dispatch],
  );

  const columns = useMemo<MRT_ColumnDef<AccessRequest>[]>(
    () => [
      {
        accessorKey: 'email',
        header: 'Email',
        size: 40,
        grow: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 40,
        grow: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 40,
        grow: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'username',
        header: 'Username',
        size: 40,
        grow: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        size: 40,
        grow: true,
        enableColumnFilter: false,
        accessorFn: (row) => formatDate(row.createdAt),
      },
    ],
    [],
  );

  const rowActions = useMemo(
    () => [
      {
        name: 'Accept',
        action: (item: AccessRequest) => handleAcceptRequest(item),
        icon: <CheckCircle />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.ACCEPT.modifier,
          PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.ACCEPT.resource,
        ),
      },
      {
        name: 'Reject',
        action: (item: AccessRequest) => handleRejectRequest(item.id),
        icon: <RemoveCircle />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.REJECT.modifier,
          PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.REJECT.resource,
        ),
      },
    ],
    [handleRejectRequest, handleAcceptRequest, ability],
  );

  const rowSelectionActions = useMemo(
    () => [
      {
        name: 'REJECT SELECTED',
        action: (indexes: string[], clearSelection: () => void) =>
          handleRejectSelectedRequest(indexes, clearSelection),
        icon: <Delete />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.REJECT.modifier,
          PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.REJECT.resource,
        ),
      },
    ],
    [handleRejectSelectedRequest, ability],
  );

  const renderDetailPanel = useMemo(
    () =>
      ({ row }: { row: MRT_Row<AccessRequest> }) => {
        return (
          <>
            <Typography>
              <strong>Reason:</strong> {row.original.reason}
            </Typography>
            <SyntaxHighlighter
              language="json"
              style={atomOneLight}
              wrapLongLines
              customStyle={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {JSON.stringify(row.original, null, 4)}
            </SyntaxHighlighter>
          </>
        );
      },
    [],
  );

  const options = useMemo(() => {
    return {
      data: accessRequests,
      columns: columns,
      rowCount: accessRequestsListMeta?.totalCount,
      enableColumnFilters: false,
      enableRowSelection: true,
      renderDetailPanel,
    };
  }, [accessRequests, columns, accessRequestsListMeta?.totalCount, renderDetailPanel]);

  return (
    <Box>
      <Title variant="h4">Access Requests Management</Title>
      <Table
        options={options}
        onChange={handleOnChange}
        rowActions={rowActions}
        isLoading={isLoading}
        rowSelectionActions={rowSelectionActions}
      />
    </Box>
  );
}
