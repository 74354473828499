import { createAsyncThunk } from '@reduxjs/toolkit';
import { Credentials } from '@/types/session';
import { localStorage } from '@/utils/storage';
import api from '@/api/apiSingleton';
import { setIsLoggedIn } from './sessionSlice';
import { withComponentErrorHandling, withDefaultErrorHandling } from '@/store/utils';

export const login = createAsyncThunk(
  'session/login',
  withComponentErrorHandling(async (payload: Credentials) => {
    const response = await api.session.login(payload);

    localStorage.save('csrfToken', response.meta.csrfToken);

    return response;
  }),
);

export const logout = createAsyncThunk(
  'session/logout',
  withDefaultErrorHandling(async (payload: undefined, thunkApi) => {
    thunkApi.dispatch(setIsLoggedIn(false));

    await api.session.logout();

    localStorage.remove('csrfToken');
  }),
);

export const getRole = createAsyncThunk('session/getRole', async (roleId: number) => {
  try {
    const role = await api.roles.get(roleId);

    return role.data;
  } catch (e) {
    console.log('Role:', (e as Error).message);

    return null;
  }
});

export const requestPasswordReset = createAsyncThunk(
  'session/requestPasswordReset',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (email: string) => {
      await api.session.requestPasswordReset(email);
    }),
  ),
);

export const resetPassword = createAsyncThunk(
  'session/resetPassword',
  withDefaultErrorHandling(
    withComponentErrorHandling(
      async (payload: { password: string; confirmPassword: string; token: string }) => {
        await api.session.resetPassword(payload);
      },
    ),
  ),
);

export const activateProfile = createAsyncThunk(
  'session/activateProfile',
  withDefaultErrorHandling(
    withComponentErrorHandling(
      async (payload: { password: string; confirmPassword: string; token: string }) => {
        await api.session.activateProfile(payload);
      },
    ),
  ),
);
