export enum EXCEPTION_CODES {
  FORMAT_ERROR = 'FORMAT_ERROR',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  USER_NOT_PENDING = 'USER_NOT_PENDING',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  ENTITY_NOT_UNIQUE = 'ENTITY_NOT_UNIQUE',
  INVALID_CSRF_TOKEN = 'INVALID_CSRF_TOKEN',
  ACTION_EXPIRED = 'ACTION_EXPIRED',
  ENTITY_IS_REFERENCED = 'ENTITY_IS_REFERENCED',
  SERVER_ERROR = 'SERVER_ERROR',
}

export enum EXCEPTION_FIELD_CODES {
  WRONG_EMAIL = 'WRONG_EMAIL',
  REQUIRED = 'REQUIRED',
  NOT_UNIQUE = 'NOT_UNIQUE',
  PASSWORD_NOT_STRONG_ENOUGH = 'PASSWORD_NOT_STRONG_ENOUGH',
  FIELDS_NOT_EQUAL = 'FIELDS_NOT_EQUAL',
  CANNOT_BE_EMPTY = 'CANNOT_BE_EMPTY',
  CANNOT_BE_NULL = 'CANNOT_BE_NULL',
  TOO_LONG = 'TOO_LONG',
  TOO_HIGH = 'TOO_HIGH',
  FORMAT_ERROR = 'FORMAT_ERROR',
  CONDITIONS_FORMAT_ERROR = 'CONDITIONS_FORMAT_ERROR',
  WRONG_PERMISSION_RESOURCE = 'WRONG_PERMISSION_RESOURCE',
  WRONG_PERMISSION_MODIFIER = 'WRONG_PERMISSION_MODIFIER',
  NOT_POSITIVE_INTEGER = 'NOT_POSITIVE_INTEGER',
}
