import { ApiException } from './exceptions/ApiException';
import errorFactory, { ErrorResponse } from './utils/errorFactory';
import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export default class ApiClientFactory {
  axiosInstance?: AxiosInstance;

  constructor(
    private baseURL: string,
    private selectCSRFToken: () => string | null,
    private authenticationErrorHandler: (error: ApiException) => void,
  ) {}

  create() {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      timeout: 10000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.initInterceptors();

    return this.axiosInstance;
  }

  initInterceptors() {
    this.axiosInstance?.interceptors.request.use(this.addCSRFTokenToHeaders);

    this.axiosInstance?.interceptors.response.use(this.handleSuccess, this.handleError);

    this.axiosInstance?.interceptors.response.use(this.handleSuccess, this.handleLogoutError);
  }

  addCSRFTokenToHeaders = (config: InternalAxiosRequestConfig) => {
    config.headers.set('csrf_token', this.selectCSRFToken());

    return config;
  };

  handleSuccess = (response: AxiosResponse) => {
    return response;
  };

  handleError = (error: AxiosError<ErrorResponse>) => {
    console.error(error);

    return Promise.reject(errorFactory(error));
  };

  handleLogoutError = (error: ApiException) => {
    this.authenticationErrorHandler(error);

    return Promise.reject(error);
  };
}
