import {
  Await,
  createBrowserRouter,
  Navigate,
  useLocation,
  useRouteLoaderData,
} from 'react-router-dom';
import { Suspense } from 'react';
import MainLayout from '@/layouts/MainLayout';
import LoginPage from '@/pages/LoginPage';
import ProfilePage from '@/pages/ProfilePage';
import UsersPage from '@/pages/UsersPage';
import PageNotFound from '@/pages/PageNotFound';
import LoaderPage from '@/pages/LoaderPage';
import { ROUTES } from '@/constants/routes';
import { useAppSelector } from '@/store/hooks';
import { selectIsLoggedIn } from '@/store/reducers/session/sessionSlice';
import { PERMISSIONS_DICT } from '@/constants/permissions';
import ErrorBoundary from './ErrorBoundary';
import DashboardLayout from '@/layouts/DashboardLayout';
import { dashboardLoader } from './loaders';
import { DashboardLoaderData } from './loaders/DashboardLoader';
import { useAbility } from '@/hooks/useAbility';
import RequestPasswordResetPage from '@/pages/RequestPasswordResetPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import ActivateProfilePage from '@/pages/ActivateProfilePage';
import RequestAccessPage from '@/pages/RequestAccessPage';
import RolesPage from '@/pages/RolesPage';
import AccessRequestsPage from '@/pages/AccessRequestsPage';
import AccessTokensPage from '@/pages/AccessTokensPage';
import PermissionsPage from '@/pages/PermissionsPage';

type PrivateRouteProps = {
  children: JSX.Element;
};

function PrivateRoute({ children }: PrivateRouteProps) {
  const location = useLocation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  return children;
}

type AccessControlRouteProps = {
  children: JSX.Element;
  resource: string;
  modifier: string;
};

function AccessControlRoute({ children, resource, modifier }: AccessControlRouteProps) {
  const ability = useAbility();

  const isAbility = ability.can(modifier, resource);

  if (!isAbility) {
    return <Navigate to={ROUTES.PROFILE_OWN} />;
  }

  return children;
}

type FallbackProps = {
  children: JSX.Element;
  routeId: string;
  errorMessage?: string;
};

type StartUpLoaderData = {
  promise: Promise<DashboardLoaderData>;
};

export function Fallback({ children, routeId, errorMessage }: FallbackProps) {
  const data = useRouteLoaderData(routeId) as StartUpLoaderData;

  return (
    <Suspense fallback={<LoaderPage />}>
      <Await resolve={data.promise} errorElement={<ErrorBoundary errorMessage={errorMessage} />}>
        {children}
      </Await>
    </Suspense>
  );
}

const Router = createBrowserRouter([
  {
    element: <MainLayout isMenu={false} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ROUTES.ROOT,
        element: <Navigate to={ROUTES.PROFILE_OWN} />,
      },
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
      {
        path: ROUTES.REQUEST_PASSWORD_RESET,
        element: <RequestPasswordResetPage />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPasswordPage />,
      },
      {
        path: ROUTES.ACTIVATE_PROFILE,
        element: <ActivateProfilePage />,
      },
      {
        path: ROUTES.REQUEST_ACCESS,
        element: <RequestAccessPage />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
  {
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        id: 'dashboardLoader',
        loader: dashboardLoader,
        element: (
          <PrivateRoute>
            <Fallback routeId="dashboardLoader">
              <DashboardLayout />
            </Fallback>
          </PrivateRoute>
        ),
        children: [
          {
            path: ROUTES.PROFILE_OWN,
            element: <ProfilePage />,
          },
          {
            path: ROUTES.PROFILE,
            element: (
              <AccessControlRoute
                resource={PERMISSIONS_DICT.ACCESS.USERS.GET.resource}
                modifier={PERMISSIONS_DICT.ACCESS.USERS.GET.modifier}
              >
                <ProfilePage />
              </AccessControlRoute>
            ),
          },
          {
            path: ROUTES.USERS,
            element: (
              <AccessControlRoute
                resource={PERMISSIONS_DICT.ACCESS.USERS.LIST.resource}
                modifier={PERMISSIONS_DICT.ACCESS.USERS.LIST.modifier}
              >
                <UsersPage />
              </AccessControlRoute>
            ),
          },
          {
            path: ROUTES.ROLES,
            element: (
              <AccessControlRoute
                resource={PERMISSIONS_DICT.ACCESS.ROLES.LIST.resource}
                modifier={PERMISSIONS_DICT.ACCESS.ROLES.LIST.modifier}
              >
                <RolesPage />
              </AccessControlRoute>
            ),
          },
          {
            path: ROUTES.PERMISSIONS,
            element: (
              <AccessControlRoute
                resource={PERMISSIONS_DICT.ACCESS.PERMISSIONS.LIST.resource}
                modifier={PERMISSIONS_DICT.ACCESS.PERMISSIONS.LIST.modifier}
              >
                <PermissionsPage />
              </AccessControlRoute>
            ),
          },
          {
            path: ROUTES.ACCESS_REQUESTS,
            element: (
              <AccessControlRoute
                resource={PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.LIST.resource}
                modifier={PERMISSIONS_DICT.ACCESS.REQUEST_ACCESS.LIST.modifier}
              >
                <AccessRequestsPage />
              </AccessControlRoute>
            ),
          },
          {
            path: ROUTES.ACCESS_TOKENS,
            element: (
              <AccessControlRoute
                resource={PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.LIST.resource}
                modifier={PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.LIST.modifier}
              >
                <AccessTokensPage />
              </AccessControlRoute>
            ),
          },
        ],
      },
    ],
  },
]);

export default Router;
