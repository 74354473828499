import Base from './Base';
import { Credentials } from '@/types/session';
import { SessionMeta, User } from '@/types/user';
import { Session as SessionType } from '@/types/session';
import { ApiResponse } from '@/types/api';

class Session extends Base {
  async login({ email, password }: Credentials) {
    const response = await this.apiClient.post<ApiResponse<User, SessionMeta>>('/auth/local', {
      login: email,
      password,
    });

    return response.data;
  }

  async logout() {
    await this.apiClient.delete('/access/sessions/own/current');
  }

  async getOwnSessions() {
    const response = await this.apiClient.get<ApiResponse<SessionType[]>>('/access/sessions/own');

    return response.data;
  }

  async getSessions(userId: number) {
    const response = await this.apiClient.get<ApiResponse<SessionType[]>>(
      `/access/sessions/${userId}`,
    );

    return response.data;
  }

  async deleteOwnSession(sessionId: number) {
    await this.apiClient.delete(`/access/sessions/own/${sessionId}`);
  }

  async deleteSession(sessionId: number, userId: number) {
    await this.apiClient.delete(`/access/sessions/${userId}/${sessionId}`);
  }

  async requestPasswordReset(email: string) {
    await this.apiClient.post('/auth/request-password-reset', { email });
  }

  async resetPassword(payload: { password: string; confirmPassword: string; token: string }) {
    await this.apiClient.patch('/auth/reset-password', payload);
  }

  async activateProfile(payload: { password: string; confirmPassword: string; token: string }) {
    await this.apiClient.patch('/auth/activate-profile', payload);
  }
}

export default Session;
