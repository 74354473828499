import { UIError } from '@/types/errors';
import { createSlice } from '@reduxjs/toolkit';

type InitialStateErrorsProps = {
  error: UIError | null;
};

const initialState: InitialStateErrorsProps = {
  error: null,
};

export const errorsSlice = createSlice({
  name: 'errors',
  reducerPath: 'errors',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearErrorState: (state) => {
      state.error = null;
    },
  },
  selectors: {
    selectError: (state) => state.error,
  },
});

export const { setError, clearErrorState } = errorsSlice.actions;
export const { selectError } = errorsSlice.selectors;
