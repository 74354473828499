import Grid from '@mui/material/Grid';
import { ResetPasswordForm } from '@/components/forms/ResetPasswordForm';
import { useState } from 'react';
import SuccessBlock from '@/components/SuccessBlock';

export default function ResetPasswordPage() {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" height="100%">
      <Grid item xs={11} md={6} lg={4}>
        {isSuccess ? (
          <SuccessBlock
            title="Reset Password"
            message="Your password has been reset successfully."
          />
        ) : (
          <ResetPasswordForm onSuccess={() => setIsSuccess(true)} />
        )}
      </Grid>
    </Grid>
  );
}
