export const SESSION_PERMISSIONS_DICT = {
  DELETE: {
    title: 'SESSIONS_DELETE',
    description: 'Controls delete session use-case',
    resource: 'SESSIONS',
    modifier: 'DELETE',
  },
  DELETE_ALL: {
    title: 'SESSIONS_DELETE_ALL',
    description: 'Controls delete all sessions use-case',
    resource: 'SESSIONS',
    modifier: 'DELETE_ALL',
  },
  GET: {
    title: 'SESSIONS_GET',
    description: 'Controls get session use-case',
    resource: 'SESSIONS',
    modifier: 'GET',
  },
  LIST: {
    title: 'SESSIONS_LIST',
    description: 'Controls list sessions use-case',
    resource: 'SESSIONS',
    modifier: 'LIST',
  },
};

export const SESSION_PERMISSIONS_LIST = Object.values(SESSION_PERMISSIONS_DICT);
