export const MARKET_RATINGS_PERMISSIONS_DICT = {
  LIST: {
    title: 'MARKET_RATINGS_LIST',
    description: 'Controls get market ratings use-case',
    resource: 'MARKET_RATINGS',
    modifier: 'LIST',
  },
};

export const MARKET_RATINGS_PERMISSIONS_LIST = Object.values(MARKET_RATINGS_PERMISSIONS_DICT);
