import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api/apiSingleton';
import { RolesListParams, RolesModifyParams } from '@/api/Roles';
import { withComponentErrorHandling, withDefaultErrorHandling } from '@/store/utils';
import { EXCEPTION_CODES } from '@/constants/errors/codes';
import { ApiException } from '@/api/exceptions/ApiException';

export const listRoles = createAsyncThunk(
  'roles/listRoles',
  withDefaultErrorHandling(async (params: RolesListParams = {}) => {
    const response = await api.roles.list(params);

    return { roles: response.data, meta: response.meta };
  }),
);

export const getRole = createAsyncThunk(
  'roles/getRole',
  withDefaultErrorHandling(async (roleId: number) => {
    const response = await api.roles.get(roleId);

    return { role: response.data, meta: response.meta };
  }),
);

export const createRole = createAsyncThunk(
  'roles/createRole',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: RolesModifyParams) => {
      const response = await api.roles.create(payload);

      return response.data;
    }),
  ),
);

export const deleteRole = createAsyncThunk(
  'roles/deleteRole',
  withDefaultErrorHandling(async (roleId: number) => {
    try {
      await api.roles.delete(roleId);

      return roleId;
    } catch (error) {
      if ((error as ApiException).code === EXCEPTION_CODES.ENTITY_IS_REFERENCED) {
        throw new Error('The Role is being used and cannot be deleted');
      }
    }
  }),
);

export const updateRole = createAsyncThunk(
  'roles/updateRole',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: { formData: RolesModifyParams; roleId: number }) => {
      const response = await api.roles.update(payload);

      return response.data;
    }),
  ),
);
