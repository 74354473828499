/* eslint-disable react/jsx-pascal-case */
import { ReactNode, useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_Row,
  MRT_RowData,
  MRT_TableOptions,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_ColumnFiltersState,
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from 'material-react-table';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ToolbarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
}));

type Action<T> = {
  name: string;
  action: (item: T) => void;
  icon: ReactNode;
  can?: boolean;
};

type ToolbarAction = {
  name: string;
  action: () => void;
  icon: ReactNode;
  can?: boolean;
};

type RowSelectionAction = {
  name: string;
  action: (indexes: string[], clearSelection: () => void) => void;
  icon: ReactNode;
  can?: boolean;
};

export type TableState = {
  pagination: MRT_PaginationState;
  sorting: MRT_SortingState;
  filters: MRT_ColumnFiltersState;
  searchFilter: string;
};

type TableProps<T extends MRT_RowData> = {
  rowActions: Action<T>[];
  options: MRT_TableOptions<T>;
  onChange?: (state: TableState) => void;
  isLoading?: boolean;
  toolbarActions?: ToolbarAction[];
  rowSelectionActions?: RowSelectionAction[];
};

const MOBILE_COLUMN_AMOUNT = 1;
const TABLET_COLUMN_AMOUNT = 3;

function prepareColumnsAmount<T extends MRT_RowData>(
  columns: MRT_ColumnDef<T>[],
  isMobile: boolean,
  isTablet: boolean,
) {
  if (isMobile && columns.length > MOBILE_COLUMN_AMOUNT) {
    return columns.slice(0, MOBILE_COLUMN_AMOUNT);
  }

  if (isTablet && columns.length > TABLET_COLUMN_AMOUNT) {
    return columns.slice(0, TABLET_COLUMN_AMOUNT);
  }

  return columns;
}

export default function Table<T extends MRT_RowData>({
  rowActions,
  options,
  onChange,
  isLoading,
  toolbarActions,
  rowSelectionActions,
}: TableProps<T>) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [searchFilter, setSearchFilter] = useState('');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isRowSelected = !!Object.keys(rowSelection).length;

  const columns = prepareColumnsAmount<T>(options.columns, isMobile, isTablet);

  const clearSelection = () => {
    setRowSelection({});
  };

  const table = useMaterialReactTable({
    layoutMode: 'grid-no-grow',
    enableTopToolbar: true,
    enableColumnFilters: true,
    enableRowActions: !!rowActions.filter((action) => action.can).length,
    enableRowSelection: false,
    enablePagination: true,
    paginationDisplayMode: 'pages',
    enableBottomToolbar: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: true,
    enableColumnActions: true,
    positionActionsColumn: 'last',
    positionExpandColumn: 'first',
    state: {
      isLoading,
      pagination,
      sorting,
      rowSelection,
    },
    initialState: {
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      shape: 'rounded',
      variant: 'outlined',
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        size: 40,
      },
      'mrt-row-actions': {
        size: 40,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: 14,
      },
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
    onGlobalFilterChange: setSearchFilter,
    onRowSelectionChange: setRowSelection,
    renderDetailPanel: ({ row }: { row: MRT_Row<T> }) => {
      return (
        <SyntaxHighlighter
          language="json"
          style={atomOneLight}
          wrapLongLines
          customStyle={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {JSON.stringify(row.original, null, 4)}
        </SyntaxHighlighter>
      );
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return rowActions.map((action) => {
        if (!action.can) return null;

        return (
          <MenuItem
            key={action.name}
            onClick={() => {
              action.action(row.original);
              closeMenu();
            }}
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            {action.name}
          </MenuItem>
        );
      });
    },
    renderTopToolbar: ({ table }) => {
      return (
        <>
          <ToolbarContainer>
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <MRT_GlobalFilterTextField table={table} />
              {table.options.enableColumnFilters && <MRT_ToggleFiltersButton table={table} />}
            </Box>
            <Box sx={{ display: 'flex', gap: '0.5rem', ml: 2 }}>
              {toolbarActions?.map((action) => {
                if (!action.can) return null;

                return (
                  <Button
                    key={action.name}
                    startIcon={action.icon}
                    onClick={action.action}
                    variant="contained"
                  >
                    {action.name}
                  </Button>
                );
              })}
            </Box>
          </ToolbarContainer>
          {isRowSelected && (
            <ToolbarContainer sx={{ py: 1 }}>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                {rowSelectionActions?.map((action) => {
                  if (!action.can) return null;

                  return (
                    <Button
                      key={action.name}
                      startIcon={action.icon}
                      onClick={() => action.action(Object.keys(rowSelection), clearSelection)}
                      variant="contained"
                      size="small"
                    >
                      {action.name}
                    </Button>
                  );
                })}
              </Box>
            </ToolbarContainer>
          )}
        </>
      );
    },
    ...options,
    columns,
  });

  useEffect(() => {
    if (onChange) {
      onChange({ pagination, sorting, filters, searchFilter });
    }
  }, [onChange, pagination, filters, searchFilter, sorting]);

  return <MaterialReactTable table={table} />;
}
