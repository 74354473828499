export const PERMISSION_PERMISSIONS_DICT = {
  CREATE: {
    title: 'PERMISSIONS_CREATE',
    description: 'Controls create permission use-case',
    resource: 'PERMISSIONS',
    modifier: 'CREATE',
  },
  DELETE: {
    title: 'PERMISSIONS_DELETE',
    description: 'Controls delete permission use-case',
    resource: 'PERMISSIONS',
    modifier: 'DELETE',
  },
  GET: {
    title: 'PERMISSIONS_GET',
    description: 'Controls get permission use-case',
    resource: 'PERMISSIONS',
    modifier: 'GET',
  },
  LIST: {
    title: 'PERMISSIONS_LIST',
    description: 'Controls list permissions use-case',
    resource: 'PERMISSIONS',
    modifier: 'LIST',
  },
  UPDATE: {
    title: 'PERMISSIONS_UPDATE',
    description: 'Controls update permission use-case',
    resource: 'PERMISSIONS',
    modifier: 'UPDATE',
  },
};

export const PERMISSION_PERMISSIONS_LIST = Object.values(PERMISSION_PERMISSIONS_DICT);
