export const USER_PERMISSIONS_DICT = {
  CREATE: {
    title: 'USERS_CREATE',
    description: 'Controls create user use-case',
    resource: 'USERS',
    modifier: 'CREATE',
  },
  DELETE: {
    title: 'USERS_DELETE',
    description: 'Controls delete user use-case',
    resource: 'USERS',
    modifier: 'DELETE',
  },
  GET: {
    title: 'USERS_GET',
    description: 'Controls get user use-case',
    resource: 'USERS',
    modifier: 'GET',
  },
  LIST: {
    title: 'USERS_LIST',
    description: 'Controls list users use-case',
    resource: 'USERS',
    modifier: 'LIST',
  },
  SEND_ACTIVATION: {
    title: 'USERS_SEND_ACTIVATION',
    description: 'Controls send activation use-case',
    resource: 'USERS',
    modifier: 'SEND_ACTIVATION',
  },
  UPDATE: {
    title: 'USERS_UPDATE',
    description: 'Controls update user use-case',
    resource: 'USERS',
    modifier: 'UPDATE',
  },
};

export const USER_PERMISSIONS_LIST = Object.values(USER_PERMISSIONS_DICT);
