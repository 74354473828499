import { CANDLE_STICK_PERMISSIONS_DICT, CANDLE_STICK_PERMISSIONS_LIST } from './candle-sticks';
import { EXCHANGE_RATE_PERMISSIONS_DICT, EXCHANGE_RATE_PERMISSIONS_LIST } from './exchange-rates';
import { MARKET_LOG_PERMISSIONS_DICT, MARKET_LOG_PERMISSIONS_LIST } from './market-logs';
import { ORDER_BOOK_PERMISSIONS_DICT, ORDER_BOOK_PERMISSIONS_LIST } from './order-books';
import { TICKER_PERMISSIONS_DICT, TICKER_PERMISSIONS_LIST } from './tickers';
import { TRADE_PERMISSIONS_DICT, TRADE_PERMISSIONS_LIST } from './trades';

export const MARKET_RECORD_PERMISSIONS_DICT = {
  CANDLE_STICKS: CANDLE_STICK_PERMISSIONS_DICT,
  EXCHANGE_RATES: EXCHANGE_RATE_PERMISSIONS_DICT,
  MARKET_LOGS: MARKET_LOG_PERMISSIONS_DICT,
  ORDER_BOOKS: ORDER_BOOK_PERMISSIONS_DICT,
  TICKERS: TICKER_PERMISSIONS_DICT,
  TRADES: TRADE_PERMISSIONS_DICT,
};

export const MARKET_RECORD_PERMISSIONS_LIST = [
  ...CANDLE_STICK_PERMISSIONS_LIST,
  ...EXCHANGE_RATE_PERMISSIONS_LIST,
  ...MARKET_LOG_PERMISSIONS_LIST,
  ...ORDER_BOOK_PERMISSIONS_LIST,
  ...TICKER_PERMISSIONS_LIST,
  ...TRADE_PERMISSIONS_LIST,
];
