import { createSlice } from '@reduxjs/toolkit';
import { deletePermission, listPermissions, updatePermission } from './actions';
import { TotalListMeta } from '@/types/api';
import { Permission } from '@/types/permission';

type InitialStateSessionProps = {
  permissions: Permission[];
  meta: TotalListMeta | null;
  isLoading: boolean;
};

const initialState: InitialStateSessionProps = {
  permissions: [],
  meta: null,
  isLoading: false,
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    clearPermissions: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listPermissions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.permissions = action.payload.permissions;
      state.meta = action.payload.meta;
    });

    builder.addCase(deletePermission.fulfilled, (state, action) => {
      state.permissions = state.permissions.filter((item) => item.id !== action.payload);
    });

    builder.addCase(updatePermission.fulfilled, (state, action) => {
      const currentPermissionIndex = state.permissions.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (currentPermissionIndex !== -1) {
        state.permissions[currentPermissionIndex] = action.payload;
      }
    });
  },
  selectors: {
    selectPermissions: (state) => state.permissions,
    selectPermissionsListMeta: (state) => state.meta,
    selectPermissionsListIsLoading: (state) => state.isLoading,
  },
});

export const { clearPermissions } = permissionsSlice.actions;
export const { selectPermissions, selectPermissionsListMeta, selectPermissionsListIsLoading } =
  permissionsSlice.selectors;
