import React, { useCallback } from 'react';
import AutocompleteField from './AutocompleteField';
import { FieldProps, RJSFSchema } from '@rjsf/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ApiResponse, ListParams } from '@/types/api';
import { styled } from '@mui/material/styles';

type Option = {
  id: number;
  title: string;
};

type Props<T> = {
  fetcher: (params: Pick<ListParams, 'search'>) => Promise<ApiResponse<T[]>>;
} & FieldProps;

type SchemaWithOptionalTitle = {
  title?: string;
};

const FieldLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError: boolean }>(({ theme, isError }) => ({
  display: 'inline-block',
  fontSize: theme.typography.caption.fontSize,
  padding: theme.spacing(0, 0.5),
  position: 'relative',
  top: theme.spacing(-1.5),
  left: theme.spacing(-0.75),
  zIndex: 1,
  background: theme.palette.background.paper,
  cursor: 'default',
  userSelect: 'none',
  color: isError ? theme.palette.error.main : theme.palette.text.primary,
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError: boolean }>(({ theme, isError }) => ({
  marginTop: 0,
  padding: theme.spacing(2),
  paddingTop: 0,
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
  borderColor: isError ? theme.palette.error.main : theme.palette.border.dark,
  borderStyle: 'solid',
}));

export default function AutocompleteListField<T extends Option>(props: Props<T>) {
  const { onChange, schema, formData = [], required, rawErrors } = props;

  const handleItemChange = useCallback(
    (index: number) => (value: T | null) => {
      const newFormData = [...formData];
      newFormData[index] = value;
      onChange(newFormData);
    },
    [formData, onChange],
  );

  const handleAddItem = useCallback(() => {
    onChange([...formData, {}]);
  }, [formData, onChange]);

  const handleRemoveItem = useCallback(
    (index: number) => () => {
      const newFormData = formData.filter((_: unknown, i: number) => i !== index);
      onChange(newFormData);
    },
    [formData, onChange],
  );

  const handleItemSchema = useCallback(
    (schema: RJSFSchema, index: number): RJSFSchema => ({
      ...schema,
      title: (schema.items as SchemaWithOptionalTitle)?.title || `Item ${index + 1}`,
    }),
    [],
  );

  const handleItemErrors = useCallback(
    (rawErrors: string[] | undefined, index: number): string[] | undefined => {
      const errorsForIndex = rawErrors?.[0]?.[index];

      if (errorsForIndex) {
        return Array.isArray(errorsForIndex) ? errorsForIndex : [errorsForIndex];
      }
    },
    [],
  );

  const filterOptions = (response: T[]) => {
    const ids = formData.map((item: T) => item.id);

    return response.filter((option: Option) => !ids.includes(option.id));
  };

  return (
    <StyledBox isError={!!rawErrors?.length}>
      <FieldLabel isError={!!rawErrors?.length} variant="body1" color="secondary">
        {schema.title} {required && '*'}
      </FieldLabel>
      {formData.map((item: unknown, index: number) => (
        <Box key={index} mb={2} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <AutocompleteField
                {...props}
                formData={item}
                onChange={handleItemChange(index)}
                schema={handleItemSchema(schema, index)}
                rawErrors={handleItemErrors(rawErrors, index)}
                filterOptions={filterOptions}
              />
            </Box>
            <Box ml={1} display="flex" alignItems="center">
              <IconButton
                aria-label="remove"
                onClick={handleRemoveItem(index)}
                color="error"
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ))}
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddItem}
        startIcon={<AddIcon />}
        fullWidth
        sx={{ mt: 2 }}
      >
        Add Item
      </Button>
    </StyledBox>
  );
}
