import { useState, useCallback } from 'react';
import MonacoEditor from '@monaco-editor/react';
import { FieldProps } from '@rjsf/utils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const FieldLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError: boolean }>(({ theme, isError }) => ({
  display: 'block',
  fontSize: theme.typography.body1.fontSize,
  marginBottom: theme.spacing(1),
  color: isError ? theme.palette.error.main : theme.palette.text.primary,
  cursor: 'default',
  userSelect: 'none',
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError: boolean }>(({ theme, isError }) => ({
  padding: theme.spacing(1),
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
  borderColor: isError ? theme.palette.error.main : theme.palette.divider,
  borderStyle: 'solid',
  backgroundColor: 'white',
}));

export default function JsonEditorField(props: FieldProps) {
  const { onChange, schema, formData, required, rawErrors } = props;
  const [code, setCode] = useState<string>(() => JSON.stringify(formData, null, 4));

  const handleChange = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        setCode(value);

        try {
          const parsedValue = JSON.parse(value);
          onChange(parsedValue);
        } catch {}
      }
    },
    [onChange],
  );

  return (
    <Box>
      <FieldLabel isError={!!rawErrors?.length}>
        {schema.title} {required && '*'}
      </FieldLabel>
      <StyledBox isError={!!rawErrors?.length}>
        <MonacoEditor
          height="300px"
          language="json"
          value={code}
          onChange={handleChange}
          options={{
            automaticLayout: true,
            minimap: { enabled: false },
            scrollbar: {
              vertical: 'auto',
              horizontal: 'auto',
            },
            fontSize: 14,
            lineNumbers: 'on',
            lineNumbersMinChars: 3,
            scrollBeyondLastLine: false,
            formatOnType: true,
            folding: true,
            wordWrap: 'on',
          }}
        />
      </StyledBox>
    </Box>
  );
}
