import { ApiResponse, ListParams, TotalListMeta } from '@/types/api';
import { AccessRequest } from '@/types/accessRequest';
import Base from './Base';

export type RequestAccessParams = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  reason: string;
};

class RequestAccess extends Base {
  async create(payload: RequestAccessParams) {
    await this.apiClient.post('/auth/request-access', payload);
  }

  async list(params: ListParams) {
    const response = await this.apiClient.get<ApiResponse<AccessRequest[], TotalListMeta>>(
      '/access/request-access',
      {
        params,
      },
    );

    return response.data;
  }

  async accept({ requestId, roleId }: { requestId: number; roleId: number }) {
    await this.apiClient.post(`/access/request-access/accept/${requestId}`, {
      roleId,
    });
  }

  async reject({ requestId, reason }: { requestId: number; reason?: string }) {
    await this.apiClient.post(`/access/request-access/reject/${requestId}`, {
      reason,
    });
  }
}

export default RequestAccess;
