import { combineSlices } from '@reduxjs/toolkit';
import { modalsSlice } from './modals/modalsSlice';
import { sessionSlice } from './session/sessionSlice';
import { profileSlice } from './profile/profileSlice';
import { usersSlice } from './users/usersSlice';
import { errorsSlice } from './errors/errorsSlice';
import { rolesSlice } from './roles/rolesSlice';
import { accessRequestsSlice } from './accessRequests/accessRequestsSlice';
import { accessTokensSlice } from './accessTokens/accessTokensSlice';
import { permissionsSlice } from './permissions/permissionsSlice';

const rootReducer = combineSlices(
  modalsSlice,
  sessionSlice,
  profileSlice,
  usersSlice,
  errorsSlice,
  rolesSlice,
  permissionsSlice,
  accessRequestsSlice,
  accessTokensSlice,
);

export default rootReducer;
