import React from 'react';
import { ThemeProvider as BaseThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import theme from '@/theme/theme';

export interface ThemeProviderProps {
  children: React.ReactNode;
  theme?: Theme;
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  return <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>;
}
