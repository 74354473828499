import { useState, SyntheticEvent, useMemo, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';
import { FieldProps } from '@rjsf/utils';
import { ApiResponse, ListParams } from '@/types/api';

type Option = {
  id: number;
  title: string;
};

type Props<T> = {
  fetcher: (params: Pick<ListParams, 'search'>) => Promise<ApiResponse<T[]>>;
  filterOptions?: (data: T[]) => T[];
} & FieldProps;

export default function AutocompleteField<T extends Option>(props: Props<T>) {
  const { fetcher, filterOptions, required, onChange, formData, schema, uiSchema } = props;

  const isDefaultOption = Object.keys(formData).length;
  const isError = !!props.rawErrors?.length;
  const shouldHideError = uiSchema?.['ui:hideError'];

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<T[]>(isDefaultOption ? [formData] : []);

  const handleChange = useCallback(
    (event: SyntheticEvent, value: T | null) => {
      onChange(value || {});
    },
    [onChange],
  );

  const handleInputChange = useMemo(() => {
    const loadOptions = async (
      event: SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      try {
        if (reason === 'reset') return;

        if (!value) {
          setOptions([]);
          return;
        }

        setLoading(true);

        const response = await fetcher({ search: value });

        const filteredOptions = filterOptions ? filterOptions(response.data) : response.data;

        setOptions(filteredOptions);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    return debounce(loadOptions, 500);
  }, [fetcher]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      value={isDefaultOption ? formData : null}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          error={isError}
          helperText={shouldHideError && isError ? props.rawErrors?.[0] : ''}
          required={required}
          label={schema.title}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
