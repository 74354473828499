import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import palette from './palette';

const theme = responsiveFontSizes(
  createTheme({
    palette,
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            color: palette.info.main,
            textDecorationColor: palette.info.light,
            '&:hover': {
              color: palette.info.dark,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
    },
  }),
);

export default theme;
