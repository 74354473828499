export const MARKET_LOG_PERMISSIONS_DICT = {
  LIST: {
    title: 'MARKET_LOGS_LIST',
    description: 'Controls list market logs use-case',
    resource: 'MARKET_LOGS',
    modifier: 'LIST',
  },
};

export const MARKET_LOG_PERMISSIONS_LIST = Object.values(MARKET_LOG_PERMISSIONS_DICT);
