import {
  closeModal as closeModalAction,
  openModal as openModalAction,
  updateModal as updateModalAction,
} from '@/store/reducers/modals/modalsSlice';
import { useAppDispatch } from '@/store/hooks';
import { AppDispatch } from '@/store/store';
import { ModalProps, ModalType } from '@/types/modals';
import { useCallback } from 'react';

type ModalPropsGetter<T extends ModalType> = (
  onClose: () => void,
  setState: (componentProps: Partial<ModalProps<T>>) => void,
) => ModalProps<T>;

type ReduxModalState<T extends ModalType> = {
  openModal: (props?: ModalProps<T>) => void;
  closeModal: () => void;
  updateModalState: (props: Partial<ModalProps<T>>) => void;
};

export function useReduxModalWithDispatch<T extends ModalType>(
  dispatch: AppDispatch,
  type: T,
  getDialogProps?: ModalPropsGetter<T>,
): ReduxModalState<T> {
  const closeModal = useCallback(() => {
    dispatch(closeModalAction({ type }));
  }, [dispatch, type]);

  const updateModalState = useCallback(
    (componentProps: Partial<ModalProps<T>>) => {
      dispatch(updateModalAction({ type, componentProps }));
    },
    [dispatch, type],
  );

  const openModal = useCallback(
    (componentProps?: ModalProps<T>) => {
      if (getDialogProps) {
        dispatch(
          openModalAction({
            type,
            componentProps: getDialogProps(closeModal, updateModalState),
          }),
        );

        return;
      }

      dispatch(openModalAction({ type, componentProps }));
    },
    [dispatch, type, getDialogProps, closeModal, updateModalState],
  );

  return { openModal, closeModal, updateModalState };
}

export function useModal<T extends ModalType>(
  type: T,
  getDialogProps?: ModalPropsGetter<T>,
): ReduxModalState<T> {
  const dispatch = useAppDispatch();

  return useReduxModalWithDispatch(dispatch, type, getDialogProps);
}
