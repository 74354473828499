export const TRADE_PERMISSIONS_DICT = {
  LIST: {
    title: 'TRADES_LIST',
    description: 'Controls list trades use-case',
    resource: 'TRADES',
    modifier: 'LIST',
  },
};

export const TRADE_PERMISSIONS_LIST = Object.values(TRADE_PERMISSIONS_DICT);
