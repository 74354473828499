import { ApiException } from '@/api/exceptions/ApiException';
import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import { useAsyncError, useRouteError } from 'react-router';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default function ErrorBoundary({ errorMessage }: { errorMessage?: string }) {
  const routeError = useRouteError() as Error;
  const asyncError = useAsyncError() as ApiException;

  console.error(routeError);
  console.error(asyncError);

  return (
    <Container>
      <Alert severity="error">
        {errorMessage || 'Something went wrong. Please contact system administrator.'}
      </Alert>
    </Container>
  );
}
