import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export function formatDate(date: string, format = 'DD.MM.YYYY HH:mm') {
  return dayjs(date).format(format);
}

export function formatDuration(seconds: number, format = 'HH[h] mm[m]') {
  return dayjs.duration(seconds, 'seconds').format(format);
}
