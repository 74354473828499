import { ErrorSchema, RJSFSchema } from '@rjsf/utils';
import Button from '@mui/material/Button';
import BaseModal from '../BaseModal';
import { PermissionModalProps } from './PermissionModalProps';
import { useCallback, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { IChangeEvent } from '@rjsf/core';
import { closeModal } from '@/store/reducers/modals/modalsSlice';
import { ModalType } from '@/types/modals';
import { PermissionsModifyParams } from '@/api/Permissions';
import { BaseForm } from '@/components/forms/BaseForm';
import { clearErrors, formatErrors } from '@/components/forms/utils/rjsfErrors';
import { PERMISSIONS_LIST } from '@/constants/permissions';

function createOneOfResourceModifierSchema(resources: string[]) {
  return resources.map((resource) => {
    const modifiers = PERMISSIONS_LIST.filter((item) => item.resource === resource);

    return {
      properties: {
        resource: {
          const: resource,
        },
        modifier: {
          type: 'string',
          enum: modifiers.map((item) => item.modifier),
        },
      },
    };
  });
}

const resources = Array.from(new Set(PERMISSIONS_LIST.map((item) => item.resource)));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const oneOfSchema = createOneOfResourceModifierSchema(resources) as any;

const schema: RJSFSchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
      title: 'Title',
    },
    description: {
      type: 'string',
      minLength: 1,
      maxLength: 2048,
      title: 'Description',
    },
    resource: {
      type: 'string',
      title: 'Resource',
      enum: resources,
    },
    conditions: {
      type: 'string',
      minLength: 1,
      maxLength: 2048,
      title: 'Conditions',
    },
  },
  dependencies: {
    resource: {
      oneOf: oneOfSchema,
    },
  },
  required: ['title', 'resource', 'modifier'],
};

const uiSchema = {
  title: {
    'ui:widget': 'text',
    'ui:placeholder': 'Enter the title',
    'ui:options': {
      label: true,
    },
  },
  description: {
    'ui:widget': 'textarea',
    'ui:placeholder': 'Enter the description',
    'ui:options': {
      rows: 5,
      label: true,
    },
  },
  resource: {
    'ui:widget': 'select',
    'ui:placeholder': 'Select the resource',
    'ui:options': {
      label: true,
    },
  },
  modifier: {
    'ui:widget': 'select',
    'ui:title': 'Modifier',
    'ui:placeholder': 'Select the modifier',
    'ui:options': {
      label: true,
    },
  },
  conditions: {
    'ui:field': 'JsonField',
  },
  'ui:order': ['title', 'description', 'resource', 'modifier', 'conditions'],
};

export const PermissionModal = ({
  title,
  buttonText,
  formData: initialFormData,
  onSubmit,
}: PermissionModalProps) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<Record<string, ErrorSchema>>({});
  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal({ type: ModalType.PermissionModal }));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async ({ formData }: IChangeEvent<PermissionsModifyParams>) => {
      try {
        await onSubmit(formData as PermissionsModifyParams);

        handleCloseModal();
      } catch (error: unknown) {
        console.error(error);

        const errors = formatErrors(error as Record<string, string>);

        setErrors(errors);
      }
    },
    [handleCloseModal, onSubmit, setErrors],
  );

  const handleChange = useCallback(
    (event: IChangeEvent, fieldId?: string) => {
      setFormData(event.formData);

      const newErrors = clearErrors(errors, fieldId);

      setErrors(newErrors);
    },
    [errors, setErrors],
  );

  return (
    <BaseModal open title={title} onClose={handleCloseModal} renderFooterCustom={() => null}>
      <BaseForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={handleSubmit}
        onChange={handleChange}
        extraErrors={errors}
      >
        <Button
          type="submit"
          variant="contained"
          sx={(theme) => ({ mt: theme.spacing(2) })}
          fullWidth
        >
          {buttonText}
        </Button>
      </BaseForm>
    </BaseModal>
  );
};
