import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ErrorSchema, RJSFSchema } from '@rjsf/utils';
import { IChangeEvent } from '@rjsf/core';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { BaseForm } from './BaseForm';
import { useAppDispatch } from '@/store/hooks';
import { requestPasswordReset } from '@/store/reducers/session/actions';
import { ROUTES } from '@/constants/routes';
import { formatErrors } from './utils/rjsfErrors';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export type LoginFromState = {
  email: string;
  password: string;
};

const schema: RJSFSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      title: 'Email',
    },
  },
};

type Props = {
  onSuccess: () => void;
};

export function RequestPasswordResetForm({ onSuccess }: Props) {
  const [formData, setFormData] = useState({ email: '' });
  const [formErrors, setFormErrors] = useState<Record<string, ErrorSchema>>({});
  const dispatch = useAppDispatch();

  const handleOnSubmit = async ({ formData }: IChangeEvent) => {
    try {
      setFormData(formData);
      await dispatch(requestPasswordReset(formData.email)).unwrap();

      onSuccess();
    } catch (error: unknown) {
      if (error instanceof Object) {
        const errors = formatErrors(error as Record<string, string>);

        setFormErrors(errors);
      } else {
        setFormErrors(formatErrors({ email: error } as Record<string, string>));
      }
    }
  };

  return (
    <FormContainer>
      <Typography variant="h4">Request Password Reset</Typography>
      <Typography variant="subtitle1">
        Enter your email address to receive a link to reset your password.
      </Typography>
      <BaseForm
        schema={schema}
        onSubmit={handleOnSubmit}
        formData={formData}
        extraErrors={formErrors}
      >
        <Button sx={{ mt: 2 }} type="submit" variant="contained" fullWidth>
          SEND RESET LINK
        </Button>
      </BaseForm>
      <LinksContainer>
        <Link to={ROUTES.LOGIN} component={NavLink} underline="always">
          Back to Login
        </Link>
      </LinksContainer>
    </FormContainer>
  );
}
