import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api/apiSingleton';
import { RequestAccessParams } from '@/api/RequestAccess';
import { withComponentErrorHandling, withDefaultErrorHandling } from '@/store/utils';
import { ListParams } from '@/types/api';

export const createRequestAccess = createAsyncThunk(
  'accessRequest/createRequestAccess',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: RequestAccessParams) => {
      await api.requestAccess.create(payload);
    }),
  ),
);

export const listAccessRequests = createAsyncThunk(
  'accessRequest/listAccessRequests',
  withDefaultErrorHandling(async (params: ListParams = {}) => {
    const response = await api.requestAccess.list(params);

    return { accessRequests: response.data, meta: response.meta };
  }),
);

export const acceptAccessRequest = createAsyncThunk(
  'accessRequest/acceptAccessRequest',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: { requestId: number; roleId: number }) => {
      await api.requestAccess.accept(payload);
    }),
  ),
);

export const rejectAccessRequest = createAsyncThunk(
  'accessRequest/rejectAccessRequest',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: { requestId: number; reason?: string }) => {
      await api.requestAccess.reject(payload);
    }),
  ),
);
