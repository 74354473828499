import { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { WidgetProps } from '@rjsf/utils';

export default function PasswordInputWidget(props: WidgetProps) {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current && showPassword) {
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length,
        );
      }
    }, 0);
  }, [showPassword]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangePassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.onChange(event.target.value);
  };

  const isError = !!props.rawErrors?.length;

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel htmlFor="outlined-adornment-password" required={props.required} error={isError}>
        {props.label}
      </InputLabel>
      <OutlinedInput
        size="small"
        name={props.name}
        required={props.required}
        value={props.value}
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        onChange={handleChangePassword}
        inputRef={inputRef}
        error={isError}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={props.label}
      />
    </FormControl>
  );
}
