import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectAccessTokens,
  selectProfile,
  selectProfileMeta,
  selectSessions,
  setProfile,
} from '@/store/reducers/profile/profileSlice';
import {
  clearSelectedAccessTokenMeta,
  selectAccessTokensListMeta,
  selectSelectedTokenMeta,
} from '@/store/reducers/accessTokens/accessTokensSlice';
import { Session } from '@/types/session';
import { formatDate } from '@/utils/date';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Table from '@/components/table/Table';
import {
  deleteSession,
  getOwnSessions,
  getProfile,
  getSessions,
  listOwnAccessTokens,
  updateProfile,
} from '@/store/reducers/profile/actions';
import { useAbility } from '@/hooks/useAbility';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { RateLimiting } from '@/components/RateLimiting';
import { getUser, updateUser } from '@/store/reducers/users/actions';
import UploadAvatar from '@/components/UploadAvatar';
import { Status } from '@/components/Status';
import { UserStatus } from '@/types/user';
import { AccessToken } from '@/types/accessToken';
import {
  deleteAccessToken,
  getAccessToken,
  listAccessTokens,
  updateAccessToken,
} from '@/store/reducers/accessTokens/actions';
import { useModal } from '@/hooks/useModal';
import { ModalType } from '@/types/modals';
import { AccessTokenFormData } from '@/components/modals/AccessTokenModal';
import { selectSessionUser } from '@/store/reducers/session/sessionSlice';
import SensitiveText from '@/components/SensitiveText';
import { PERMISSIONS_DICT } from '@/constants/permissions';

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ProfileContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(2),
}));

export default function ProfilePage() {
  const profile = useAppSelector(selectProfile);
  const profileMeta = useAppSelector(selectProfileMeta);
  const sessions = useAppSelector(selectSessions);
  const sessionUser = useAppSelector(selectSessionUser);
  const accessTokens = useAppSelector(selectAccessTokens);
  const accessTokensListMeta = useAppSelector(selectAccessTokensListMeta);
  const selectedTokenMeta = useAppSelector(selectSelectedTokenMeta);
  const dispatch = useAppDispatch();
  const ability = useAbility();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { id: userId } = useParams();

  const { openModal: openAccessTokenModal } = useModal(ModalType.AccessTokenModal);
  const { openModal: openSuccessModal } = useModal(ModalType.SuccessModal);
  const { openModal: openUserModal } = useModal(ModalType.UserModal);
  const { openModal: openConfirmationModal } = useModal(ModalType.ConfirmationModal);

  useEffect(() => {
    const loadProfile = async () => {
      if (userId) {
        const data = await dispatch(getUser(+userId)).unwrap();
        await dispatch(setProfile(data));
        dispatch(getSessions(+userId));
        dispatch(listAccessTokens({ assignee: data.user.email }));
      } else {
        if (profile?.id !== sessionUser?.id) {
          dispatch(getProfile());
        }
        dispatch(getOwnSessions());
        dispatch(listOwnAccessTokens());
      }
    };

    loadProfile();
  }, [dispatch, userId]);

  const handleDeleteSession = useCallback(
    (session: Session) => {
      dispatch(deleteSession({ sessionId: session.id, userId: session.userId }));
    },
    [dispatch],
  );

  const handleUploadAvatar = (userId: number) => async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (event?.target?.files?.[0]) {
        const file = event.target.files[0];
        const formData = new FormData();

        formData.append('avatar', file);

        await dispatch(updateUser({ formData: formData, userId })).unwrap();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenEditProfile = useCallback(() => {
    openUserModal({
      title: 'Edit Profile',
      buttonText: 'EDIT PROFILE',
      isProfile: true,
      isUpdate: true,
      formData: {
        ...profile,
        roleId: undefined,
        avatar: profile?.avatarUrl,
      },
      onSubmit: async (formData: FormData) => {
        await dispatch(updateProfile({ formData })).unwrap();

        openSuccessModal({
          title: 'Profile Successfully Updated',
          buttonText: 'OK',
        });
      },
    });
  }, [dispatch, profile, openUserModal, openSuccessModal]);

  const handleEditAccessToken = useCallback(
    (item: AccessToken) => {
      openAccessTokenModal({
        title: 'Edit Access Token',
        buttonText: 'UPDATE',
        formData: {
          title: item.title,
          description: item.description || undefined,
          roleId: item.role,
          assigneeId: {
            id: item.assignee.id,
            title: `${item.assignee.firstName} ${item.assignee.lastName} (${item.assignee.email})`,
          },
          expiresAt: item.expiresAt,
        },
        onSubmit: async (formData: AccessTokenFormData) => {
          await dispatch(
            updateAccessToken({
              formData: {
                ...formData,
                roleId: formData.roleId.id,
                assigneeId: formData.assigneeId?.id,
              },
              tokenId: item.id,
            }),
          ).unwrap();

          openSuccessModal({
            title: 'Access Token Successfully Updated',
            buttonText: 'OK',
          });
        },
      });
    },
    [dispatch, openSuccessModal, openAccessTokenModal],
  );

  const handleDeleteAccessToken = useCallback(
    (tokenId: string) => {
      openConfirmationModal({
        title: 'Delete Access Token',
        buttonText: 'DELETE',
        message: 'Are you sure you want to delete the access token?',
        onSubmit: async () => {
          await dispatch(deleteAccessToken(tokenId)).unwrap();
        },
      });
    },
    [dispatch, openConfirmationModal],
  );

  const sessionsColumns = useMemo<MRT_ColumnDef<Session>[]>(
    () => [
      {
        accessorKey: 'createdAt',
        header: 'Date',
        size: 40,
        grow: true,
        accessorFn: (row) => formatDate(row.createdAt),
      },
      {
        accessorKey: 'useragent.ip',
        header: 'IP - Address',
        size: 40,
        grow: true,
      },
      {
        accessorKey: 'useragent.browser',
        header: 'Browser',
        size: 40,
        grow: true,
      },
      {
        accessorKey: 'useragent.platform',
        header: 'Platform',
        size: 40,
        grow: true,
      },
    ],
    [],
  );

  const accessTokensColumns = useMemo<MRT_ColumnDef<AccessToken>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        grow: true,
        size: 40,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'role',
        header: 'Role',
        size: 40,
        grow: true,
        enableSorting: false,
        accessorFn: (row) => row.role.title,
      },
      {
        accessorKey: 'assignee',
        header: 'Assignee',
        size: 40,
        grow: true,
        enableSorting: false,
        accessorFn: (row) => row.assignee.email,
      },
      {
        accessorKey: 'expiresAt',
        header: 'Expires At',
        size: 40,
        grow: true,
        enableColumnFilter: false,
        accessorFn: (row) => formatDate(row.expiresAt),
      },
    ],
    [],
  );

  const sessionsRowActions = useMemo(
    () => [
      {
        name: 'Delete',
        action: (session: Session) => handleDeleteSession(session),
        icon: <Delete />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.SESSIONS.DELETE.modifier,
          PERMISSIONS_DICT.ACCESS.SESSIONS.DELETE.resource,
        ),
      },
    ],
    [handleDeleteSession, ability],
  );

  const accessTokensRowActions = useMemo(
    () => [
      {
        name: 'Edit',
        action: (item: AccessToken) => handleEditAccessToken(item),
        icon: <Edit />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.UPDATE.modifier,
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.UPDATE.resource,
        ),
      },
      {
        name: 'Delete',
        action: (item: AccessToken) => handleDeleteAccessToken(item.id),
        icon: <Delete />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.DELETE.modifier,
          PERMISSIONS_DICT.ACCESS.ACCESS_TOKENS.DELETE.resource,
        ),
      },
    ],
    [handleEditAccessToken, handleDeleteAccessToken, ability],
  );

  const renderDetailPanel = useMemo(
    () =>
      ({ row }: { row: MRT_Row<AccessToken> }) => {
        const json = {
          ...row.original,
          id: undefined,
        };

        return (
          <>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">Access Token</Typography>
              <SensitiveText>{row.original.id}</SensitiveText>
            </Box>
            <RateLimiting rateLimit={selectedTokenMeta?.rateLimit} />
            <SyntaxHighlighter
              language="json"
              style={atomOneLight}
              wrapLongLines
              customStyle={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {JSON.stringify(json, null, 4)}
            </SyntaxHighlighter>
          </>
        );
      },
    [selectedTokenMeta],
  );

  const sessionOptions = useMemo(() => {
    return {
      data: sessions,
      columns: sessionsColumns,
      enableTopToolbar: false,
      enableColumnFilters: false,
      enablePagination: false,
      enableBottomToolbar: false,
      manualSorting: false,
      enableGlobalFilter: true,
      enableColumnActions: true,
    };
  }, [sessions, sessionsColumns]);

  const accessTokensOptions = useMemo(() => {
    return {
      data: accessTokens,
      columns: accessTokensColumns,
      rowCount: accessTokensListMeta?.totalCount,
      enableExpandAll: false,
      enableTopToolbar: false,
      enableColumnFilters: false,
      enablePagination: false,
      enableBottomToolbar: false,
      manualSorting: false,
      enableGlobalFilter: false,
      enableColumnActions: true,
      renderDetailPanel,
      muiExpandButtonProps: ({ row }: { row: MRT_Row<AccessToken> }) => ({
        onClick: () => {
          if (!row.getIsExpanded()) {
            dispatch(getAccessToken(row.original.id));
          } else {
            dispatch(clearSelectedAccessTokenMeta());
          }
        },
      }),
    };
  }, [
    dispatch,
    renderDetailPanel,
    accessTokens,
    accessTokensColumns,
    accessTokensListMeta?.totalCount,
  ]);

  if (!profile) return null;

  const canUpdate = profile.id === sessionUser?.id;

  return (
    <Container>
      <ProfileContainer>
        <Card sx={{ background: 'white', width: '100%' }}>
          <CardContent>
            <Title variant="h4">Profile</Title>
            <UploadAvatar
              id="profileAvatar"
              avatarUrl={profile.avatarUrl || undefined}
              onChange={handleUploadAvatar(profile.id)}
            />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
              sx={(theme) => ({ marginTop: theme.spacing(2) })}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Grid
                  container
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent="center"
                  alignItems={isMobile ? 'flex-start' : 'center'}
                  sx={{ maxWidth: 500 }}
                  spacing={2}
                >
                  <Grid item xs={5}>
                    <Typography>
                      <strong>First name</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography noWrap>{profile.firstName}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>
                      <strong>Middle name</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography noWrap>{profile.middleName}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>
                      <strong>Last name</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography noWrap>{profile.lastName}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>
                      <strong>Email</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography noWrap>{profile.email}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>
                      <strong>Username</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography noWrap>{profile.username}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>
                      <strong>Role</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography noWrap>{profile.role.title}</Typography>
                  </Grid>
                  {profile.status && (
                    <>
                      <Grid item xs={5}>
                        <Typography>
                          <strong>Status</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Status status={profile.status as UserStatus} />
                      </Grid>
                    </>
                  )}
                </Grid>
                {canUpdate && (
                  <Button variant="contained" sx={{ mt: 2 }} onClick={handleOpenEditProfile}>
                    EDIT PROFILE
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <RateLimiting rateLimit={profileMeta?.rateLimit} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ProfileContainer>
      <Typography variant="h6">Sessions</Typography>
      <Table options={sessionOptions} rowActions={sessionsRowActions} />
      <Typography variant="h6">Access Tokens</Typography>
      <Table options={accessTokensOptions} rowActions={accessTokensRowActions} />
    </Container>
  );
}
