import { createSlice } from '@reduxjs/toolkit';
import { deleteUser, listUsers, updateUser } from './actions';
import { User } from '@/types/user';
import { TotalListMeta } from '@/types/api';

type InitialStateSessionProps = {
  users: User[];
  meta: TotalListMeta | null;
  isLoading: boolean;
};

const initialState: InitialStateSessionProps = {
  users: [],
  meta: null,
  isLoading: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload.users;
      state.meta = action.payload.meta;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.users = state.users.filter((item) => item.id !== action.payload);
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const currentUserIndex = state.users.findIndex((item) => item.id === action.payload.id);

      if (currentUserIndex !== -1) {
        state.users[currentUserIndex] = action.payload;
      }
    });
  },
  selectors: {
    selectUsers: (state) => state.users,
    selectUsersListMeta: (state) => state.meta,
    selectUsersListIsLoading: (state) => state.isLoading,
  },
});

export const { clearUsers } = usersSlice.actions;
export const { selectUsers, selectUsersListMeta, selectUsersListIsLoading } = usersSlice.selectors;
