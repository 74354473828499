export const ROLE_PERMISSIONS_DICT = {
  CREATE: {
    title: 'ROLES_CREATE',
    description: 'Controls create role use-case',
    resource: 'ROLES',
    modifier: 'CREATE',
  },
  DELETE: {
    title: 'ROLES_DELETE',
    description: 'Controls delete role use-case',
    resource: 'ROLES',
    modifier: 'DELETE',
  },
  GET: {
    title: 'ROLES_GET',
    description: 'Controls get role use-case',
    resource: 'ROLES',
    modifier: 'GET',
  },
  LIST: {
    title: 'ROLES_LIST',
    description: 'Controls list roles use-case',
    resource: 'ROLES',
    modifier: 'LIST',
  },
  UPDATE: {
    title: 'ROLES_UPDATE',
    description: 'Controls update role use-case',
    resource: 'ROLES',
    modifier: 'UPDATE',
  },
};

export const ROLE_PERMISSIONS_LIST = Object.values(ROLE_PERMISSIONS_DICT);
