import {
  AGGREGATION_TASK_PERMISSIONS_DICT,
  AGGREGATION_TASK_PERMISSIONS_LIST,
} from './aggregation-tasks';
import { EXCHANGE_PERMISSIONS_DICT, EXCHANGE_PERMISSIONS_LIST } from './exchanges';
import {
  MARKET_RECORD_PERMISSIONS_DICT,
  MARKET_RECORD_PERMISSIONS_LIST,
} from './market-records/index';
import { MARKET_RATINGS_PERMISSIONS_DICT, MARKET_RATINGS_PERMISSIONS_LIST } from './market-ratings';
import { MARKET_PERMISSIONS_DICT, MARKET_PERMISSIONS_LIST } from './markets';
import { TOKEN_PERMISSIONS_DICT, TOKEN_PERMISSIONS_LIST } from './tokens';

export const CRYPTO_PERMISSIONS_DICT = {
  AGGREGATION_TASKS: AGGREGATION_TASK_PERMISSIONS_DICT,
  EXCHANGES: EXCHANGE_PERMISSIONS_DICT,
  MARKET_RATINGS: MARKET_RATINGS_PERMISSIONS_DICT,
  MARKET_RECORDS: MARKET_RECORD_PERMISSIONS_DICT,
  MARKETS: MARKET_PERMISSIONS_DICT,
  TOKENS: TOKEN_PERMISSIONS_DICT,
};

export const CRYPTO_PERMISSIONS_LIST = [
  ...AGGREGATION_TASK_PERMISSIONS_LIST,
  ...EXCHANGE_PERMISSIONS_LIST,
  ...MARKET_RATINGS_PERMISSIONS_LIST,
  ...MARKET_RECORD_PERMISSIONS_LIST,
  ...MARKET_PERMISSIONS_LIST,
  ...TOKEN_PERMISSIONS_LIST,
];
