export const REQUEST_ACCESS_PERMISSIONS_DICT = {
  REJECT: {
    title: 'REQUEST_ACCESS_REJECT',
    description: 'Controls reject request access use-case',
    resource: 'REQUEST_ACCESS',
    modifier: 'REJECT',
  },
  ACCEPT: {
    title: 'REQUEST_ACCESS_ACCEPT',
    description: 'Controls accept request access use-case',
    resource: 'REQUEST_ACCESS',
    modifier: 'ACCEPT',
  },
  LIST: {
    title: 'REQUEST_ACCESS_LIST',
    description: 'Controls list request access use-case',
    resource: 'REQUEST_ACCESS',
    modifier: 'LIST',
  },
};

export const REQUEST_ACCESS_PERMISSIONS_LIST = Object.values(REQUEST_ACCESS_PERMISSIONS_DICT);
