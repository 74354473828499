export const MARKET_PERMISSIONS_DICT = {
  CREATE: {
    title: 'MARKETS_CREATE',
    description: 'Controls create markets use-case',
    resource: 'MARKETS',
    modifier: 'CREATE',
  },
  DELETE: {
    title: 'MARKETS_DELETE',
    description: 'Controls delete markets use-case',
    resource: 'MARKETS',
    modifier: 'DELETE',
  },
  GET: {
    title: 'MARKETS_GET',
    description: 'Controls get market use-case',
    resource: 'MARKETS',
    modifier: 'GET',
  },
  LIST: {
    title: 'MARKETS_LIST',
    description: 'Controls list market use-case',
    resource: 'MARKETS',
    modifier: 'LIST',
  },
  TOGGLE: {
    title: 'MARKETS_TOGGLE',
    description: 'Controls toggle markets use-case',
    resource: 'MARKETS',
    modifier: 'TOGGLE',
  },
  UPDATE_MANY: {
    title: 'MARKETS_UPDATE_MANY',
    description: 'Controls update many markets use-case',
    resource: 'MARKETS',
    modifier: 'UPDATE_MANY',
  },
  UPDATE: {
    title: 'MARKETS_UPDATE',
    description: 'Controls update market use-case',
    resource: 'MARKETS',
    modifier: 'UPDATE',
  },
};

export const MARKET_PERMISSIONS_LIST = Object.values(MARKET_PERMISSIONS_DICT);
