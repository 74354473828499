import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { ActivateProfileForm } from '@/components/forms/ActivateProfileForm';
import SuccessBlock from '@/components/SuccessBlock';

export default function ActivateProfilePage() {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" height="100%">
      <Grid item xs={11} md={6} lg={4}>
        {isSuccess ? (
          <SuccessBlock
            title="Activate Profile"
            message="Your profile has been activated successfully."
          />
        ) : (
          <ActivateProfileForm onSuccess={() => setIsSuccess(true)} />
        )}
      </Grid>
    </Grid>
  );
}
