import { createSlice } from '@reduxjs/toolkit';
import { ProfileMeta, User } from '@/types/user';
import {
  deleteSession,
  getOwnSessions,
  getProfile,
  getSessions,
  listOwnAccessTokens,
  updateProfile,
} from './actions';
import { Session } from '@/types/session';
import { updateUser } from '../users/actions';
import { AccessToken } from '@/types/accessToken';
import { deleteAccessToken, listAccessTokens, updateAccessToken } from '../accessTokens/actions';

type InitialStateProfileProps = {
  profile: User | null;
  meta: ProfileMeta | null;
  sessions: Session[];
  accessTokens: AccessToken[];
  error: string | null;
  isLoading: boolean;
};

const initialState: InitialStateProfileProps = {
  profile: null,
  meta: null,
  sessions: [],
  accessTokens: [],
  error: null,
  isLoading: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload.user;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload.user;
      state.meta = action.payload.meta;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });

    builder.addCase(getOwnSessions.fulfilled, (state, action) => {
      state.sessions = action.payload;
    });

    builder.addCase(getSessions.fulfilled, (state, action) => {
      state.sessions = action.payload;
    });

    builder.addCase(deleteSession.fulfilled, (state, action) => {
      state.sessions = state.sessions.filter((item) => item.id !== action.payload);
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.profile = action.payload;
    });

    builder.addCase(listOwnAccessTokens.fulfilled, (state, action) => {
      state.accessTokens = action.payload;
    });

    builder.addCase(listAccessTokens.fulfilled, (state, action) => {
      state.accessTokens = action.payload.accessTokens;
    });

    builder.addCase(updateAccessToken.fulfilled, (state, action) => {
      const currentIndex = state.accessTokens.findIndex((item) => item.id === action.payload.id);

      if (currentIndex !== -1) {
        const profile = state.profile;

        if (profile?.id === action.payload.assigneeId) {
          state.accessTokens[currentIndex] = action.payload;
        } else {
          const newList = [...state.accessTokens];

          newList.splice(currentIndex, 1);

          state.accessTokens = newList;
        }
      }
    });

    builder.addCase(deleteAccessToken.fulfilled, (state, action) => {
      state.accessTokens = state.accessTokens.filter((item) => item.id !== action.payload);
    });
  },
  selectors: {
    selectProfile: (state) => state.profile,
    selectProfileMeta: (state) => state.meta,
    selectProfileIsLoading: (state) => state.isLoading,
    selectSessions: (state) => state.sessions,
    selectAccessTokens: (state) => state.accessTokens,
  },
});

export const { setProfile } = profileSlice.actions;
export const {
  selectProfile,
  selectProfileIsLoading,
  selectSessions,
  selectAccessTokens,
  selectProfileMeta,
} = profileSlice.selectors;
