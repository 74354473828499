export const SQL_API_PERMISSIONS_DICT = {
  READ: {
    title: 'SQL_API_READ',
    description: 'Controls read use case for SQL API',
    resource: 'SQL_API',
    modifier: 'READ',
  },
};

export const SQL_API_PERMISSIONS_LIST = Object.values(SQL_API_PERMISSIONS_DICT);
