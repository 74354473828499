import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  height: 30,
}));

export interface BaseModalProps extends DialogProps {
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  scroll?: 'body' | 'paper';
  children?: React.ReactNode;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  dividers?: boolean;
  isSubmitButtonDisabled?: boolean;
  isSubmitButtonProcessing?: boolean;
  isSubmitOnEnter?: boolean;
  renderHeaderCustom?: () => React.ReactNode;
  renderBodyCustom?: () => React.ReactNode;
  renderFooterCustom?: () => React.ReactNode;
  submitButtonText?: string;
  cancelButtonText?: string;
  draggableId?: string;
}

function BaseModal({
  title,
  open,
  scroll = 'body',
  onSubmit,
  onCancel,
  onClose,
  children,
  maxWidth = 'sm',
  dividers = false,
  fullWidth = true,
  isSubmitButtonDisabled = false,
  isSubmitButtonProcessing = false,
  isSubmitOnEnter = false,
  renderHeaderCustom,
  renderBodyCustom,
  renderFooterCustom,
  submitButtonText = 'Submit',
  cancelButtonText = 'Cancel',
  PaperComponent,
}: BaseModalProps) {
  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    if (onClose) onClose();
  };

  const handleSubmitOnEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isSubmitOnEnter && event.key === 'Enter') {
      if (onSubmit) onSubmit();
    }
  };

  const renderHeaderDefault = useCallback(() => {
    return (
      <DialogTitle component="div">
        <TitleContainer>
          <Typography textAlign="center" noWrap variant="h5">
            {title}
          </Typography>
          {onClose && (
            <IconButton onClick={onClose} sx={{ position: 'absolute', right: 0 }}>
              <CloseIcon />
            </IconButton>
          )}
        </TitleContainer>
      </DialogTitle>
    );
  }, [title, onClose]);

  const renderBodyDefault = () => {
    return <DialogContent dividers={dividers}>{children}</DialogContent>;
  };

  const renderFooterDefault = () => {
    return (
      <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
        <Button variant="contained" onClick={handleCancel} fullWidth>
          {cancelButtonText}
        </Button>
        <LoadingButton
          loading={isSubmitButtonProcessing}
          variant="contained"
          disabled={isSubmitButtonDisabled || isSubmitButtonProcessing}
          onClick={handleSubmit}
          fullWidth
        >
          {submitButtonText}
        </LoadingButton>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      maxWidth={maxWidth}
      scroll={scroll}
      fullWidth={fullWidth}
      onKeyUp={handleSubmitOnEnter}
      PaperComponent={PaperComponent}
    >
      {renderHeaderCustom ? renderHeaderCustom() : renderHeaderDefault()}
      {renderBodyCustom ? renderBodyCustom() : renderBodyDefault()}
      {renderFooterCustom ? renderFooterCustom() : renderFooterDefault()}
    </Dialog>
  );
}
export default BaseModal;
