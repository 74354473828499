import dayjs, { Dayjs } from 'dayjs';
import { FieldProps } from '@rjsf/utils';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DateTimePickerField(props: FieldProps) {
  const handleChange = (value: Dayjs | null) => {
    props.onChange(value?.toISOString());
  };

  return (
    <DateTimePicker
      value={props.formData ? dayjs(props.formData) : null}
      label={props.schema.title}
      onChange={handleChange}
      format="DD/MM/YYYY HH:mm"
      disablePast
      ampm={false}
    />
  );
}
