import { Role } from './role';

export type User = {
  id: number;
  email: string;
  username: string;
  firstName: string;
  middleName: string;
  lastName: string;
  roleId: number;
  avatarUrl: string | null;
  status: string;
  role: Omit<Role, 'permissions'>;
  createdAt: string;
  updatedAt: string;
};

export type SessionMeta = {
  csrfToken: string | null;
};

export type ProfileMeta = {
  rateLimit: {
    totalPoints: number;
    currentPoints: number;
    currentTTL: number;
    totalTTL: number;
  };
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  PENDING = 'PENDING',
}
