import { GetThunkAPI } from '@reduxjs/toolkit';
import { setError } from './reducers/errors/errorsSlice';
import { UIErrorTypes } from '@/types/errors';
import { ApiException } from '@/api/exceptions/ApiException';
import { EXCEPTION_CODES } from '@/constants/errors/codes';

type ThunkApiConfig = object;

export function withDefaultErrorHandling<Payload, Returned>(
  payloadCreator: (payload: Payload, thunkApi: GetThunkAPI<ThunkApiConfig>) => Promise<Returned>,
) {
  return async (payload: Payload, thunkApi: GetThunkAPI<ThunkApiConfig>) => {
    try {
      return await payloadCreator(payload, thunkApi);
    } catch (error: unknown) {
      thunkApi?.dispatch(setError({ type: UIErrorTypes.Snack, message: (error as Error).message }));

      throw error;
    }
  };
}

export function withComponentErrorHandling<Payload, Returned>(
  payloadCreator: (payload: Payload, thunkApi: GetThunkAPI<ThunkApiConfig>) => Promise<Returned>,
) {
  return async (payload: Payload, thunkApi: GetThunkAPI<ThunkApiConfig>) => {
    try {
      return await payloadCreator(payload, thunkApi);
    } catch (error: unknown) {
      if (error instanceof ApiException && error.code !== EXCEPTION_CODES.SERVER_ERROR) {
        return thunkApi.rejectWithValue(error.fields || error.message);
      }

      throw error;
    }
  };
}
