import { createSlice } from '@reduxjs/toolkit';
import { SessionMeta, User } from '@/types/user';
import { getRole, login, logout } from './actions';
import { Role } from '@/types/role';

type InitialStateSessionProps = {
  user: User | null;
  meta: SessionMeta;
  role: Role | null;
  isLoggedIn: boolean;
  isLoading: boolean;
};

const initialState: InitialStateSessionProps = {
  user: null,
  meta: {
    csrfToken: null,
  },
  role: null,
  isLoggedIn: false,
  isLoading: false,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setCSRFToken: (state, action) => {
      state.meta.csrfToken = action.payload;
    },
    setSessionUser: (state, action) => {
      state.user = action.payload;
    },
    clearSession: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoggedIn = true;
    });

    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });

    builder.addCase(getRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.role = action.payload;
    });
  },
  selectors: {
    selectIsLoggedIn: (state) => state.isLoggedIn,
    selectCSRFToken: (state) => state.meta.csrfToken,
    selectRole: (state) => state.role,
    selectSessionUser: (state) => state.user,
  },
});

export const { setIsLoggedIn, setCSRFToken, clearSession, setSessionUser } = sessionSlice.actions;
export const { selectIsLoggedIn, selectCSRFToken, selectRole, selectSessionUser } =
  sessionSlice.selectors;
