import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api/apiSingleton';
import { withComponentErrorHandling, withDefaultErrorHandling } from '@/store/utils';
import { ListParams } from '@/types/api';
import { AccessTokenParams } from '@/api/AccessTokens';

export type AccessTokenListParams = ListParams & { assignee?: string };

export const createAccessToken = createAsyncThunk(
  'accessTokens/createAccessToken',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: AccessTokenParams) => {
      await api.accessTokens.create(payload);
    }),
  ),
);

export const listAccessTokens = createAsyncThunk(
  'accessTokens/listAccessTokens',
  withDefaultErrorHandling(async (params: AccessTokenListParams = {}) => {
    const response = await api.accessTokens.list(params);

    return { accessTokens: response.data, meta: response.meta };
  }),
);

export const getAccessToken = createAsyncThunk(
  'accessTokens/getAccessTokens',
  withDefaultErrorHandling(async (tokenId: string) => {
    const response = await api.accessTokens.get(tokenId);

    return { accessTokens: response.data, meta: response.meta };
  }),
);

export const updateAccessToken = createAsyncThunk(
  'accessTokens/updateAccessToken',
  withDefaultErrorHandling(
    withComponentErrorHandling(
      async (payload: { formData: AccessTokenParams; tokenId: string }) => {
        const response = await api.accessTokens.update(payload);

        return response.data;
      },
    ),
  ),
);

export const deleteAccessToken = createAsyncThunk(
  'accessTokens/deleteAccessToken',
  withDefaultErrorHandling(async (tokenId: string) => {
    await api.accessTokens.delete(tokenId);

    return tokenId;
  }),
);
