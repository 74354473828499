import { EXCEPTION_CODES, EXCEPTION_FIELD_CODES } from './codes';

const MESSAGES: Record<EXCEPTION_CODES, string> = {
  [EXCEPTION_CODES.FORMAT_ERROR]: 'Format error',
  [EXCEPTION_CODES.PERMISSION_DENIED]: 'Permission denied',
  [EXCEPTION_CODES.INVALID_CREDENTIALS]: 'Invalid credentials',
  [EXCEPTION_CODES.USER_NOT_PENDING]: 'User is not active',
  [EXCEPTION_CODES.NOT_AUTHENTICATED]: 'User not authenticated',
  [EXCEPTION_CODES.ENTITY_NOT_FOUND]: 'Not found',
  [EXCEPTION_CODES.ENTITY_NOT_UNIQUE]: 'Not unique',
  [EXCEPTION_CODES.INVALID_CSRF_TOKEN]: 'Invalid CSRF token',
  [EXCEPTION_CODES.ACTION_EXPIRED]: 'Token expired',
  [EXCEPTION_CODES.ENTITY_IS_REFERENCED]: 'Entity can not be removed',
  [EXCEPTION_CODES.SERVER_ERROR]: 'Server error. Please contact system administrator.',
};

const FIELD_MESSAGES: Record<EXCEPTION_FIELD_CODES, string> = {
  [EXCEPTION_FIELD_CODES.WRONG_EMAIL]: 'Wrong email format',
  [EXCEPTION_FIELD_CODES.REQUIRED]: 'The field is required',
  [EXCEPTION_FIELD_CODES.NOT_UNIQUE]: 'Not unique',
  [EXCEPTION_FIELD_CODES.PASSWORD_NOT_STRONG_ENOUGH]: 'Password not strong enough',
  [EXCEPTION_FIELD_CODES.FIELDS_NOT_EQUAL]: 'Fields not equal',
  [EXCEPTION_FIELD_CODES.CANNOT_BE_EMPTY]: 'Can not be empty',
  [EXCEPTION_FIELD_CODES.CANNOT_BE_NULL]: 'Can not be null',
  [EXCEPTION_FIELD_CODES.TOO_LONG]: 'The value is too long',
  [EXCEPTION_FIELD_CODES.FORMAT_ERROR]: 'The value is of invalid type',
  [EXCEPTION_FIELD_CODES.CONDITIONS_FORMAT_ERROR]: 'Invalid conditions format',
  [EXCEPTION_FIELD_CODES.WRONG_PERMISSION_RESOURCE]: 'Invalid permission resource',
  [EXCEPTION_FIELD_CODES.WRONG_PERMISSION_MODIFIER]: 'Invalid permission modifier',
  [EXCEPTION_FIELD_CODES.NOT_POSITIVE_INTEGER]: 'Not a positive integer',
  [EXCEPTION_FIELD_CODES.TOO_HIGH]: 'The value is to large',
};

type FieldsType = Record<
  string,
  EXCEPTION_FIELD_CODES | EXCEPTION_FIELD_CODES[] | { code: EXCEPTION_FIELD_CODES; message: string }
>;

export const getErrorMessage = (code?: EXCEPTION_CODES): string => {
  return (code && MESSAGES[code]) || MESSAGES[EXCEPTION_CODES.SERVER_ERROR];
};

export const getFieldErrorMessages = (fields?: FieldsType) => {
  if (!fields) return;

  const fieldMessages: Record<string, string | string[]> = {};

  Object.entries(fields).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      fieldMessages[key] = value.map((item: EXCEPTION_FIELD_CODES) => FIELD_MESSAGES[item]);
    } else if (typeof value === 'object') {
      fieldMessages[key] = `${FIELD_MESSAGES[value.code]}: ${value.message}`;
    } else {
      fieldMessages[key] = FIELD_MESSAGES[value];
    }
  });

  return fieldMessages;
};
