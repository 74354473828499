import React from 'react';
import {
  UserModal,
  RoleModal,
  ErrorModal,
  ConfirmationModal,
  SelectRoleModal,
  RejectAccessRequestModal,
  AccessTokenModal,
  SuccessModal,
} from '@/components/modals';
import { selectActiveModals } from '@/store/reducers/modals/modalsSlice';
import { Modal, ModalType } from '@/types/modals';
import { useAppSelector } from '@/store/hooks';
import { PermissionModal } from './PermissionModal';

const MODALS: { [key in ModalType]: React.ElementType } = {
  [ModalType.UserModal]: UserModal,
  [ModalType.RoleModal]: RoleModal,
  [ModalType.PermissionModal]: PermissionModal,
  [ModalType.ErrorModal]: ErrorModal,
  [ModalType.ConfirmationModal]: ConfirmationModal,
  [ModalType.SelectRoleModal]: SelectRoleModal,
  [ModalType.RejectAccessRequestModal]: RejectAccessRequestModal,
  [ModalType.AccessTokenModal]: AccessTokenModal,
  [ModalType.SuccessModal]: SuccessModal,
};

function ModalContent() {
  const activeModals: Modal[] = useAppSelector(selectActiveModals);

  return (
    <>
      {activeModals.map(({ type, componentProps }) => {
        const Modal = MODALS[type];

        return <Modal key={type} {...componentProps} />;
      })}
    </>
  );
}

export default ModalContent;
