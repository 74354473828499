import { useCallback, useMemo, useState } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import { MRT_ColumnDef } from 'material-react-table';
import { useAbility } from '@casl/react';
import Delete from '@mui/icons-material/Delete';
import Launch from '@mui/icons-material/Launch';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Table, { TableState } from '@/components/table/Table';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectUsers,
  selectUsersListIsLoading,
  selectUsersListMeta,
} from '@/store/reducers/users/usersSlice';
import { User, UserStatus } from '@/types/user';
import { createUser, deleteUser, listUsers, updateUser } from '@/store/reducers/users/actions';
import { ROUTES } from '@/constants/routes';
import { Status } from '@/components/Status';
import { ListParams } from '@/types/api';
import { useModal } from '@/hooks/useModal';
import { ModalType } from '@/types/modals';
import { AbilityContext } from '@/contexts/accessControlContext';
import { PERMISSIONS_DICT } from '@/constants/permissions';

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function UsersPage() {
  const users = useAppSelector(selectUsers);
  const usersListMeta = useAppSelector(selectUsersListMeta);
  const isLoading = useAppSelector(selectUsersListIsLoading);
  const ability = useAbility(AbilityContext);
  const dispatch = useAppDispatch();
  const [paramsState, setParamsState] = useState<ListParams>({});

  const { openModal: openUserModal } = useModal(ModalType.UserModal);
  const { openModal: openSuccessModal } = useModal(ModalType.SuccessModal);
  const { openModal: openConfirmationModal } = useModal(ModalType.ConfirmationModal);

  const handleOpenCreateUser = useCallback(() => {
    openUserModal({
      title: 'Create User',
      buttonText: 'CREATE USER',
      onSubmit: async (formData: FormData) => {
        await dispatch(createUser(formData)).unwrap();

        openSuccessModal({
          title: 'User Successfully Created',
          buttonText: 'OK',
        });

        dispatch(listUsers(paramsState));
      },
    });
  }, [paramsState, openSuccessModal, dispatch, openUserModal]);

  const handleEditUser = useCallback(
    (item: User) => {
      openUserModal({
        title: 'Edit User',
        buttonText: 'UPDATE USER',
        isUpdate: true,
        formData: {
          ...item,
          roleId: item.role,
          avatar: item.avatarUrl,
        },
        onSubmit: async (formData: FormData) => {
          await dispatch(updateUser({ formData, userId: item.id })).unwrap();

          openSuccessModal({
            title: 'User Successfully Updated',
            buttonText: 'OK',
          });
        },
      });
    },
    [dispatch, openSuccessModal, openUserModal],
  );

  const handleDeleteUsers = useCallback(
    (userId: number) => {
      openConfirmationModal({
        title: 'Delete User',
        buttonText: 'DELETE',
        message: 'Are you sure you want to delete the user?',
        onSubmit: async () => {
          await dispatch(deleteUser(userId)).unwrap();
        },
      });
    },
    [dispatch, openConfirmationModal],
  );

  const handleOnChange = useCallback(
    (state: TableState) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filters = state.filters.reduce<Record<string, string>>((acc, curr: any) => {
        acc[curr.id] = curr.value;

        return acc;
      }, {});

      const params: ListParams = {
        ...filters,
        limit: state.pagination.pageSize,
        offset: state.pagination.pageSize * state.pagination.pageIndex,
        sortBy: state.sorting?.[0]?.id,
        orderBy: state.sorting?.[0]?.desc ? 'DESC' : 'ASC',
        search: state.searchFilter || null,
      };

      setParamsState(params);

      dispatch(listUsers(params));
    },
    [dispatch],
  );

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        header: 'User',
        accessorKey: 'user',
        size: 40,
        grow: true,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Avatar id={`avatar-${row.original.id}`} src={row.original.avatarUrl ?? undefined} />
            <Link
              to={generatePath(ROUTES.PROFILE, { id: row.original.id.toString() })}
              component={NavLink}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {row.original.firstName + ' ' + row.original.lastName}
              <Launch fontSize="small" />
            </Link>
          </Box>
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 40,
        grow: true,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'role',
        header: 'Role',
        size: 40,
        grow: true,
        enableSorting: false,
        accessorFn: (row) => row.role.title,
      },
      {
        accessorKey: 'username',
        header: 'Username',
        size: 40,
        grow: true,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 40,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: Object.values(UserStatus),
        Cell: ({ row }) => <Status status={row.original.status as UserStatus} />,
      },
    ],
    [],
  );

  const rowActions = useMemo(
    () => [
      {
        name: 'Edit',
        action: (item: User) => handleEditUser(item),
        icon: <Edit />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.USERS.UPDATE.modifier,
          PERMISSIONS_DICT.ACCESS.USERS.UPDATE.resource,
        ),
      },
      {
        name: 'Delete',
        action: (item: User) => handleDeleteUsers(item.id),
        icon: <Delete />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.USERS.DELETE.modifier,
          PERMISSIONS_DICT.ACCESS.USERS.DELETE.resource,
        ),
      },
    ],
    [handleDeleteUsers, handleEditUser, ability],
  );

  const toolbarActions = useMemo(
    () => [
      {
        name: 'NEW USER',
        action: handleOpenCreateUser,
        icon: <Add />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.USERS.CREATE.modifier,
          PERMISSIONS_DICT.ACCESS.USERS.CREATE.resource,
        ),
      },
    ],
    [handleOpenCreateUser, ability],
  );

  const options = useMemo(() => {
    return {
      data: users,
      columns: columns,
      rowCount: usersListMeta?.totalCount,
    };
  }, [users, columns, usersListMeta?.totalCount]);

  return (
    <Box>
      <Title variant="h4">User Management</Title>
      <Table
        options={options}
        onChange={handleOnChange}
        rowActions={rowActions}
        toolbarActions={toolbarActions}
        isLoading={isLoading}
      />
    </Box>
  );
}
