import { ErrorSchema, RJSFSchema, UiSchema } from '@rjsf/utils';
import Button from '@mui/material/Button';
import BaseModal from '../BaseModal';
import { UserModalProps } from './UserModalProps';
import { BaseForm } from '@/components/forms/BaseForm';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { IChangeEvent } from '@rjsf/core';
import { closeModal } from '@/store/reducers/modals/modalsSlice';
import { ModalType } from '@/types/modals';
import { clearErrors, formatErrors } from '@/components/forms/utils/rjsfErrors';
import { UserStatus } from '@/types/user';

function createSchema(isProfile?: boolean, isUpdate?: boolean): RJSFSchema {
  const schema: RJSFSchema = {
    type: 'object',
    properties: {
      avatar: {
        type: 'object',
        title: 'Avatar',
      },
      firstName: {
        type: 'string',
        title: 'First name',
      },
      middleName: {
        type: 'string',
        title: 'Middle name',
      },
      lastName: {
        type: 'string',
        title: 'Last name',
      },
      email: {
        type: 'string',
        title: 'Email',
      },
      username: {
        type: 'string',
        title: 'Username',
      },
      roleId: {
        type: 'object',
        title: 'Role',
      },
      status: {
        type: 'string',
        enum: Object.values(UserStatus),
        title: 'Status',
        default: UserStatus.PENDING,
      },
      password: {
        type: 'string',
        title: 'Password',
      },
      confirmPassword: {
        type: 'string',
        title: 'Confirm password',
      },
    },
    required: [
      'firstName',
      'lastName',
      'email',
      'username',
      'roleId',
      'password',
      'confirmPassword',
    ],
  };

  if (isProfile) {
    delete schema?.properties?.roleId;
    delete schema?.properties?.status;
  }

  if (isUpdate) {
    schema.required = [];
  }

  return schema;
}

const uiSchema: UiSchema = {
  avatar: {
    'ui:field': 'AvatarField',
  },
  roleId: {
    'ui:field': 'RolesAutocompleteField',
  },
  password: {
    'ui:widget': 'PasswordInputWidget',
  },
  confirmPassword: {
    'ui:widget': 'PasswordInputWidget',
  },
};

export const UserModal = ({
  title,
  buttonText,
  formData: initialFormData,
  onSubmit,
  isProfile,
  isUpdate,
}: UserModalProps) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<Record<string, ErrorSchema>>({});
  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal({ type: ModalType.UserModal }));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async ({ formData }: IChangeEvent) => {
      try {
        const formDataToSend = new FormData();

        if (formData.avatar instanceof File) {
          formDataToSend.append('avatar', formData.avatar);
        }

        formData.email && formDataToSend.append('email', formData.email);
        formData.firstName && formDataToSend.append('firstName', formData.firstName);
        formData.middleName && formDataToSend.append('middleName', formData.middleName);
        formData.lastName && formDataToSend.append('lastName', formData.lastName);
        formData.username && formDataToSend.append('username', formData.username);
        formData.roleId?.id && formDataToSend.append('roleId', formData.roleId.id);
        formData.status && formDataToSend.append('status', formData.status);
        formData.password && formDataToSend.append('password', formData.password);
        formData.confirmPassword &&
          formDataToSend.append('confirmPassword', formData.confirmPassword);

        await onSubmit(formDataToSend);

        handleCloseModal();
      } catch (error: unknown) {
        console.error(error);

        const errors = formatErrors(error as Record<string, string>);

        setErrors(errors);
      }
    },
    [handleCloseModal, onSubmit],
  );

  const handleChange = useCallback(
    (event: IChangeEvent, fieldId?: string) => {
      setFormData(event.formData);

      const newErrors = clearErrors(errors, fieldId);

      setErrors(newErrors);
    },
    [errors],
  );

  const schema = useMemo(() => createSchema(isProfile, isUpdate), [isProfile, isUpdate]);

  return (
    <BaseModal open title={title} onClose={handleCloseModal} renderFooterCustom={() => null}>
      <BaseForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={handleSubmit}
        onChange={handleChange}
        extraErrors={errors}
      >
        <Button
          type="submit"
          variant="contained"
          sx={(theme) => ({ mt: theme.spacing(2) })}
          fullWidth
        >
          {buttonText}
        </Button>
      </BaseForm>
    </BaseModal>
  );
};
