import { Permission } from '@/types/permission';
import Base from './Base';
import { ApiResponse, ListParams, TotalListMeta } from '@/types/api';

export type PermissionsListParams = ListParams;

export type PermissionsModifyParams = {
  title: string;
  description?: string;
  resource?: string;
  modifier?: string;
  conditions?: object | null;
};

class Permissions extends Base {
  list = async (params: PermissionsListParams) => {
    const response = await this.apiClient.get<ApiResponse<Permission[], TotalListMeta>>(
      '/access/permissions',
      {
        params,
      },
    );

    return response.data;
  };

  async get(permissionId: number) {
    const response = await this.apiClient.get<ApiResponse<Permission>>(
      `/access/permissions/${permissionId}`,
    );

    return response.data;
  }

  async create(formData: PermissionsModifyParams) {
    const response = await this.apiClient.post<ApiResponse<Permission, null>>(
      '/access/permissions',
      formData,
    );

    return response.data;
  }

  async delete(permissionId: number) {
    await this.apiClient.delete(`/access/permissions/${permissionId}`);
  }

  async update({
    formData,
    permissionId,
  }: {
    formData: PermissionsModifyParams;
    permissionId: number;
  }) {
    const response = await this.apiClient.patch<ApiResponse<Permission>>(
      `/access/permissions/${permissionId}`,
      formData,
    );

    return response.data;
  }
}

export default Permissions;
