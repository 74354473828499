export enum ROUTES {
  ROOT = '/',
  PROFILE = '/profile/:id',
  PROFILE_OWN = '/profile',
  LOGIN = '/login',
  USERS = '/users',
  ROLES = '/roles',
  PERMISSIONS = '/permissions',
  ACCESS_TOKENS = '/access-tokens',
  ACCESS_REQUESTS = '/access-requests',
  REQUEST_ACCESS = '/request-access',
  RESET_PASSWORD = '/reset-password/:token',
  REQUEST_PASSWORD_RESET = '/request-password-reset',
  ACTIVATE_PROFILE = '/activate-profile/:token',
}
