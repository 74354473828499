import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ContentCopy } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';

type Props = {
  children: string;
};

export default function SensitiveText({ children }: Props) {
  const [isVisible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const handleToggleVisible = () => setVisible((isVisible) => !isVisible);
  const handleCopy = () => {
    setOpen(true);

    navigator.clipboard.writeText(children);
  };

  const text = isVisible ? children : children.replace(/./g, '*');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        wordBreak: 'break-word',
      }}
    >
      <Typography>{text}</Typography>
      <Box
        sx={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleToggleVisible}
      >
        {isVisible ? <Visibility /> : <VisibilityOff />}
      </Box>
      <ContentCopy sx={{ cursor: 'pointer' }} onClick={handleCopy} />
      <Snackbar
        message="Copied to clibboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </Box>
  );
}
