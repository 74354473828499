import {
  selectCSRFToken as CSRFSelector,
  clearSession,
} from '@/store/reducers/session/sessionSlice';
import { EXCEPTION_CODES } from '@/constants/errors/codes';
import { ApiException } from '@/api/exceptions/ApiException';
import { store } from '@/store/store';
import { localStorage } from '@/utils/storage';

const AUTH_CODES = [EXCEPTION_CODES.NOT_AUTHENTICATED, EXCEPTION_CODES.INVALID_CSRF_TOKEN];

export const authenticationErrorHandler = async (error: ApiException) => {
  if (AUTH_CODES.includes(error.code)) {
    store.dispatch(clearSession());
    localStorage.remove('csrfToken');
  }
};

export const selectCSRFToken = () => CSRFSelector(store.getState());
