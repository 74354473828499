import BaseLoader from './BaseLoader';
import { ProfileMeta, User } from '@/types/user';
import { Role } from '@/types/role';
import { store } from '@/store/store';
import { getProfile } from '@/store/reducers/profile/actions';
import { setSessionUser } from '@/store/reducers/session/sessionSlice';
import { getRole } from '@/store/reducers/session/actions';

export type DashboardLoaderData = {
  profile: {
    user: User;
    meta: ProfileMeta;
  };
  role: Role | null;
};

export default class DashboardLoader extends BaseLoader<DashboardLoaderData | null> {
  loader() {
    return this.initProfile();
  }

  async initProfile() {
    const profile = await store.dispatch(getProfile()).unwrap();
    const role = await store.dispatch(getRole(profile.user.roleId)).unwrap();
    await store.dispatch(setSessionUser(profile.user));

    return { profile, role };
  }
}
