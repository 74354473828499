import { ACCESS_PERMISSIONS_DICT, ACCESS_PERMISSIONS_LIST } from './access';
import { CRYPTO_PERMISSIONS_DICT, CRYPTO_PERMISSIONS_LIST } from './crypto';
import { SQL_API_PERMISSIONS_DICT, SQL_API_PERMISSIONS_LIST } from './sql-api';
import { STATIC_API_PERMISSIONS_DICT, STATIC_API_PERMISSIONS_LIST } from './static-api';
import { WS_API_PERMISSIONS_DICT, WS_API_PERMISSIONS_LIST } from './ws-api';

export const PERMISSIONS_DICT = {
  ACCESS: ACCESS_PERMISSIONS_DICT,
  CRYPTO: CRYPTO_PERMISSIONS_DICT,
  WS_API: WS_API_PERMISSIONS_DICT,
  SQL_API: SQL_API_PERMISSIONS_DICT,
  STATIC_API: STATIC_API_PERMISSIONS_DICT,
};

export const PERMISSIONS_LIST = [
  ...ACCESS_PERMISSIONS_LIST,
  ...CRYPTO_PERMISSIONS_LIST,
  ...WS_API_PERMISSIONS_LIST,
  ...SQL_API_PERMISSIONS_LIST,
  ...STATIC_API_PERMISSIONS_LIST,
];
