import { useCallback, useState } from 'react';
import { ErrorSchema, RJSFSchema } from '@rjsf/utils';
import { IChangeEvent } from '@rjsf/core';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { BaseForm } from './BaseForm';
import { useAppDispatch } from '@/store/hooks';
import { clearErrors, formatErrors } from './utils/rjsfErrors';
import { createRequestAccess } from '@/store/reducers/accessRequests/actions';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const ErrorMessageContainer = styled('div')(({ theme }) => ({
  height: 18,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

export type LoginFromState = {
  email: string;
  password: string;
};

const schema: RJSFSchema = {
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      title: 'First name',
    },
    middleName: {
      type: 'string',
      title: 'Middle name',
    },
    lastName: {
      type: 'string',
      title: 'Last name',
    },
    email: {
      type: 'string',
      title: 'Email',
    },
    username: {
      type: 'string',
      title: 'Username',
    },
    reason: {
      type: 'string',
      title: 'Reason',
    },
  },
  required: ['firstName', 'lastName', 'email', 'username', 'reason'],
};

const uiSchema = {
  reason: {
    'ui:widget': 'textarea',
  },
};

type Props = {
  onSuccess: () => void;
};

export function RequestAccessForm({ onSuccess }: Props) {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState<Record<string, ErrorSchema>>({});
  const dispatch = useAppDispatch();

  const handleOnSubmit = useCallback(
    async ({ formData }: IChangeEvent) => {
      try {
        setFormData(formData);
        await dispatch(createRequestAccess(formData)).unwrap();

        onSuccess();
      } catch (error: unknown) {
        console.error(error);

        if (error instanceof Object) {
          const errors = formatErrors(error as Record<string, string>);

          setFormErrors(errors);
        } else {
          setError(error as string);
        }
      }
    },
    [dispatch, onSuccess],
  );

  const handleChange = useCallback(
    (event: IChangeEvent, fieldId?: string) => {
      setFormData(event.formData);

      const newErrors = clearErrors(formErrors, fieldId);

      setFormErrors(newErrors);
    },
    [formErrors],
  );

  return (
    <FormContainer>
      <Typography variant="h4">Request Access</Typography>
      <Typography variant="subtitle1" textAlign="center">
        Fill out the form to request access to the desired features.
      </Typography>
      <BaseForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        extraErrors={formErrors}
        onSubmit={handleOnSubmit}
        onChange={handleChange}
      >
        <ErrorMessageContainer>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </ErrorMessageContainer>
        <Button type="submit" variant="contained" fullWidth>
          SUBMIT REQUEST
        </Button>
      </BaseForm>
    </FormContainer>
  );
}
