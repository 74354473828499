import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api/apiSingleton';
import { withComponentErrorHandling, withDefaultErrorHandling } from '@/store/utils';

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  withDefaultErrorHandling(async (payload: undefined) => {
    const data = await api.profile.get();

    const profile = { user: data.data, meta: data.meta };

    return profile;
  }),
);

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  withDefaultErrorHandling(
    withComponentErrorHandling(async (payload: { formData: FormData }) => {
      const response = await api.profile.update(payload);

      return response.data;
    }),
  ),
);

export const getOwnSessions = createAsyncThunk(
  'profile/getOwnSessions',
  withDefaultErrorHandling(async (payload: undefined) => {
    const response = await api.session.getOwnSessions();

    return response.data;
  }),
);

export const getSessions = createAsyncThunk(
  'profile/getSessions',
  withDefaultErrorHandling(async (userId: number) => {
    const response = await api.session.getSessions(userId);

    return response.data;
  }),
);

export const deleteSession = createAsyncThunk(
  'profile/deleteSession',
  withDefaultErrorHandling(async ({ sessionId, userId }: { sessionId: number; userId: number }) => {
    await api.session.deleteSession(sessionId, userId);

    return sessionId;
  }),
);

export const listOwnAccessTokens = createAsyncThunk(
  'profile/listOwnAccessTokens',
  withDefaultErrorHandling(async (payload: undefined) => {
    const response = await api.accessTokens.getOwnAccessTokens();

    return response.data;
  }),
);
