import { ErrorSchema } from '@rjsf/utils';

export function formatErrors(error: Record<string, string>) {
  const errors: Record<string, ErrorSchema> = {};

  for (const key in error) {
    errors[key] = { __errors: [error[key]] } as ErrorSchema;
  }

  return errors;
}

export function clearErrors(errors: Record<string, ErrorSchema>, fieldId?: string) {
  const newErrors: Record<string, ErrorSchema> = {};

  for (const key in errors) {
    if (fieldId?.replace('root_', '') !== key) {
      newErrors[key] = errors[key];
    }
  }

  return newErrors;
}
