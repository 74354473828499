import { ErrorSchema, RJSFSchema } from '@rjsf/utils';
import Button from '@mui/material/Button';
import BaseModal from '../BaseModal';
import { RoleFormData, RoleModalProps } from './RoleModalProps';
import { useCallback, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { IChangeEvent } from '@rjsf/core';
import { closeModal } from '@/store/reducers/modals/modalsSlice';
import { ModalType } from '@/types/modals';
import { RolesModifyParams } from '@/api/Roles';
import { BaseForm } from '@/components/forms/BaseForm';
import { clearErrors, formatErrors } from '@/components/forms/utils/rjsfErrors';

const schema: RJSFSchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
      title: 'Title',
    },
    description: {
      type: 'string',
      minLength: 1,
      maxLength: 2048,
      title: 'Description',
    },
    permissions: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        minimum: 1,
        title: 'Permission',
      },
      title: 'Permissions',
    },
    rateLimit: {
      type: 'integer',
      minimum: 1,
      title: 'Rate Limit',
    },
  },
  required: ['title', 'permissions', 'rateLimit'],
};

const uiSchema = {
  title: {
    'ui:widget': 'text',
    'ui:placeholder': 'Enter the title',
    'ui:options': {
      label: true,
    },
  },
  description: {
    'ui:widget': 'textarea',
    'ui:placeholder': 'Enter the description',
    'ui:options': {
      rows: 5,
      label: true,
    },
  },
  permissions: {
    'ui:field': 'PermissionsAutocompleteListField',
    'ui:hideError': true,
  },
  rateLimit: {
    'ui:widget': 'updown',
    'ui:placeholder': 'Enter the rate limit',
    'ui:options': {
      label: true,
    },
  },
};

export const RoleModal = ({
  title,
  buttonText,
  formData: initialFormData,
  onSubmit,
}: RoleModalProps) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<Record<string, ErrorSchema>>({});
  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal({ type: ModalType.RoleModal }));
  }, [dispatch]);

  const handleErrors = useCallback((errors: Record<string, ErrorSchema>) => {
    const { permissionIds, ...nativeErrors } = errors;

    setErrors({
      ...nativeErrors,
      ...(permissionIds && { permissions: permissionIds }),
    });
  }, []);

  const handleSubmit = useCallback(
    async ({ formData }: IChangeEvent<RoleFormData>) => {
      try {
        const transformedData = {
          ...formData,
          permissionIds: formData?.permissions.map((perm) => perm.id),
          permissions: undefined,
        };

        await onSubmit(transformedData as RolesModifyParams);

        handleCloseModal();
      } catch (error: unknown) {
        console.error(error);

        const errors = formatErrors(error as Record<string, string>);

        handleErrors(errors);
      }
    },
    [handleCloseModal, onSubmit, handleErrors],
  );

  const handleChange = useCallback(
    (event: IChangeEvent, fieldId?: string) => {
      setFormData(event.formData);

      const newErrors = clearErrors(errors, fieldId);

      handleErrors(newErrors);
    },
    [errors, handleErrors],
  );

  return (
    <BaseModal open title={title} onClose={handleCloseModal} renderFooterCustom={() => null}>
      <BaseForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={handleSubmit}
        onChange={handleChange}
        extraErrors={errors}
      >
        <Button
          type="submit"
          variant="contained"
          sx={(theme) => ({ mt: theme.spacing(2) })}
          fullWidth
        >
          {buttonText}
        </Button>
      </BaseForm>
    </BaseModal>
  );
};
