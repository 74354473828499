export const CANDLE_STICK_PERMISSIONS_DICT = {
  CREATE_COMPLETE_AGGREGATION_TASK: {
    title: 'CANDLE_STICKS_CREATE_COMPLETE_AGGREGATION_TASK',
    description: 'Controls create complete aggregation task use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'CREATE_COMPLETE_AGGREGATION_TASK',
  },
  GET_CANDLES_COMPLETE_AGGREGATION_TASK_RESULTS: {
    title: 'CANDLE_STICKS_GET_CANDLES_COMPLETE_AGGREGATION_TASK_RESULTS',
    description: 'Controls get candles complete aggregation task results use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'GET_CANDLES_COMPLETE_AGGREGATION_TASK_RESULTS',
  },
  LIST_CANDLES_COMPLETE_AGGREGATION_TASK_RESULTS: {
    title: 'CANDLE_STICKS_LIST_CANDLES_COMPLETE_AGGREGATION_TASK_RESULTS',
    description: 'Controls list candles complete aggregation task results use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'LIST_CANDLES_COMPLETE_AGGREGATION_TASK_RESULTS',
  },
  CREATE_DISCRETE_AGGREGATION_TASK: {
    title: 'CANDLE_STICKS_CREATE_DISCRETE_AGGREGATION_TASK',
    description: 'Controls create discrete aggregation task use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'CREATE_DISCRETE_AGGREGATION_TASK',
  },
  GET_CANDLES_DISCRETE_AGGREGATION_TASK_RESULTS: {
    title: 'CANDLE_STICKS_GET_CANDLES_DISCRETE_AGGREGATION_TASK_RESULTS',
    description: 'Controls get candles discrete aggregation task results use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'GET_CANDLES_DISCRETE_AGGREGATION_TASK_RESULTS',
  },
  GET_CANDLES_DISCRETE_AGGREGATION: {
    title: 'CANDLE_STICKS_GET_CANDLES_DISCRETE_AGGREGATION',
    description: 'Controls get candles discrete aggregation use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'GET_CANDLES_DISCRETE_AGGREGATION',
  },
  LIST_CANDLES_DISCRETE_AGGREGATIONS: {
    title: 'CANDLE_STICKS_LIST_CANDLES_DISCRETE_AGGREGATIONS',
    description: 'Controls list candles discrete aggregations use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'LIST_CANDLES_DISCRETE_AGGREGATIONS',
  },
  LIST_CANDLES_DISCRETE_AGGREGATION_TASK_RESULTS: {
    title: 'CANDLE_STICKS_LIST_CANDLES_DISCRETE_AGGREGATION_TASK_RESULTS',
    description: 'Controls list candles discrete aggregation task results use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'LIST_CANDLES_DISCRETE_AGGREGATION_TASK_RESULTS',
  },
  LIST: {
    title: 'CANDLE_STICKS_LIST',
    description: 'Controls list candle sticks use-case',
    resource: 'CANDLE_STICKS',
    modifier: 'LIST',
  },
};

export const CANDLE_STICK_PERMISSIONS_LIST = Object.values(CANDLE_STICK_PERMISSIONS_DICT);
