import { Outlet } from 'react-router';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import ModalContent from '@/components/modals/ModalContent';
import AccessControlProvider from '@/providers/AccessControlProvider';
import { styled } from '@mui/material/styles';
import Snackbar from '@/components/Snackbar';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MainLayout = ({ isMenu }: { isMenu?: boolean }) => {
  return (
    <AccessControlProvider>
      <Container>
        <Snackbar />
        <ModalContent />
        <Header isMenu={isMenu} />
        <Container>
          <Outlet />
        </Container>
        <Footer />
      </Container>
    </AccessControlProvider>
  );
};

export default MainLayout;
