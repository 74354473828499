import { ReactNode } from 'react';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { FormProps } from '@rjsf/core';
import {
  CustomValidator,
  ErrorSchema,
  FieldProps,
  RJSFSchema,
  RegistryFieldsType,
  RegistryWidgetsType,
  UiSchema,
} from '@rjsf/utils';
import PasswordInputWidget from './rjsfWidgets/PasswordInputWidget';
import AvatarField from './rjsfFields/AvatarField';
import AutocompleteField from './rjsfFields/AutocompleteField';
import { inject } from '../../hocs/withInject';
import api from '@/api/apiSingleton';
import AutocompleteListField from './rjsfFields/AutocompleteListField';
import JsonField from './rjsfFields/JsonField';
import DateTimePickerField from './rjsfFields/DateTimePickerField';

type RolesFetcher = FieldProps & { fetcher: typeof api.roles.list };

type PermissionsFetcher = FieldProps & {
  fetcher: typeof api.permissions.list;
};

type UsersFetcher = FieldProps & {
  fetcher: typeof api.users.autocompleteUsersList;
};

const widgets: RegistryWidgetsType = {
  PasswordInputWidget,
};

const fields: RegistryFieldsType = {
  AvatarField,
  JsonField,
  RolesAutocompleteField: inject<RolesFetcher, 'fetcher'>(AutocompleteField, {
    fetcher: api.roles.list,
  }),
  UsersAutocompleteField: inject<UsersFetcher, 'fetcher'>(AutocompleteField, {
    fetcher: api.users.autocompleteUsersList,
  }),
  PermissionsAutocompleteListField: inject<PermissionsFetcher, 'fetcher'>(AutocompleteListField, {
    fetcher: api.permissions.list,
  }),
  DateTimePickerField,
};

type Props = {
  schema: RJSFSchema;
  uiSchema?: UiSchema;
  customValidate?: CustomValidator;
  formData?: FormProps['formData'];
  onSubmit: FormProps['onSubmit'];
  onChange?: FormProps['onChange'];
  formContext?: FormProps['formContext'];
  extraErrors?: ErrorSchema;
  children?: ReactNode;
  noHtml5Validate?: boolean;
  noValidate?: boolean;
};

export function BaseForm({
  schema,
  uiSchema,
  onSubmit,
  onChange,
  extraErrors,
  formData,
  formContext,
  customValidate,
  children,
  noHtml5Validate = true,
  noValidate = true,
}: Props) {
  return (
    <Form
      className="rjsf-form"
      schema={schema}
      validator={validator}
      customValidate={customValidate}
      uiSchema={uiSchema}
      fields={fields}
      widgets={widgets}
      onSubmit={onSubmit}
      onChange={onChange}
      showErrorList={false}
      formData={formData}
      extraErrors={extraErrors}
      formContext={formContext}
      noHtml5Validate={noHtml5Validate}
      noValidate={noValidate}
    >
      {children}
    </Form>
  );
}
