import { Outlet } from 'react-router';
import NavigationMenu from '@/components/NavigationMenu';
import styled from '@emotion/styled';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const DashboardLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      {!isMobile && <NavigationMenu />}
      <Box sx={{ m: 2, width: '100%' }}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default DashboardLayout;
