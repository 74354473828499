export const ORDER_BOOK_PERMISSIONS_DICT = {
  LIST: {
    title: 'ORDER_BOOKS_LIST',
    description: 'Controls list order books use-case',
    resource: 'ORDER_BOOKS',
    modifier: 'LIST',
  },
};

export const ORDER_BOOK_PERMISSIONS_LIST = Object.values(ORDER_BOOK_PERMISSIONS_DICT);
