import numeral from 'numeral';
import { ProfileMeta } from '@/types/user';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PieChart } from '@mui/x-charts/PieChart';
import Grid from '@mui/material/Grid';
import { formatDuration } from '@/utils/date';
import { formatNumber } from '@/utils/numeral';

const RateLimitInfoContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
}));

const pieChartSize = {
  width: 150,
  height: 150,
};

type RateLimitingProps = {
  rateLimit?: ProfileMeta['rateLimit'];
};

export function RateLimiting({ rateLimit }: RateLimitingProps) {
  if (!rateLimit) return null;

  const { totalPoints, currentPoints, totalTTL, currentTTL } = rateLimit;
  const isUnlimited = totalPoints === null;
  const pointsUsed = totalPoints - currentPoints;
  const remainingPoints = totalPoints - pointsUsed;
  const remainingPercentage = numeral(remainingPoints / totalPoints).format('0.00%');

  const data = [
    {
      value: remainingPoints,
      color: '#79C91F',
      label: 'Remain',
    },
    {
      value: pointsUsed,
      color: '#5388D8',
      label: 'Used',
    },
  ];

  return (
    <>
      <Typography variant="h6">Rate limiting</Typography>
      <Card sx={{ boxShadow: 'none', maxWidth: 500 }}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            {!isUnlimited ? (
              <Grid item>
                <Box sx={{ position: 'relative' }} {...pieChartSize}>
                  <PieChart
                    {...pieChartSize}
                    series={[
                      {
                        data,
                        innerRadius: 40,
                        outerRadius: 70,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: 360,
                        endAngle: 0,
                        cx: 70,
                        cy: 70,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        hidden: true,
                      },
                    }}
                  />
                  <RateLimitInfoContainer>
                    <Typography variant="h6">{remainingPercentage}</Typography>
                  </RateLimitInfoContainer>
                </Box>
              </Grid>
            ) : null}
            <Grid item xs={12} md={12} lg>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item xs={6}>
                  <Typography>Total points:</Typography>
                  <Typography>
                    <strong>{isUnlimited ? 'Unlimited' : formatNumber(totalPoints)}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Points used:</Typography>
                  <Typography>
                    <strong>{isUnlimited ? 'Unlimited' : formatNumber(pointsUsed)}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Remaining points:</Typography>
                  <Typography>
                    <strong>{isUnlimited ? 'Unlimited' : formatNumber(remainingPoints)}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Overall time:</Typography>
                  <Typography>
                    <strong>
                      {isUnlimited ? 'Unlimited' : formatNumber(totalTTL / 3600) + 'hours'}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Time to expire:</Typography>
                  <Typography>
                    <strong>{isUnlimited ? 'Unlimited' : formatDuration(currentTTL)}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
