import { ApiResponse, ListParams, TotalListMeta } from '@/types/api';
import { AccessToken, AccessTokenMeta } from '@/types/accessToken';
import Base from './Base';

export type AccessTokenParams = {
  title: string;
  description?: string;
  roleId: number;
  assigneeId: number;
  expiresAt?: string;
};

class AccessTokens extends Base {
  async get(tokenId: string) {
    const response = await this.apiClient.get<ApiResponse<AccessToken[], AccessTokenMeta>>(
      `/access/access-tokens/${tokenId}`,
    );

    return response.data;
  }

  async create(payload: AccessTokenParams) {
    await this.apiClient.post('/access/access-tokens', payload);
  }

  async list(params: ListParams) {
    const response = await this.apiClient.get<ApiResponse<AccessToken[], TotalListMeta>>(
      '/access/access-tokens',
      {
        params,
      },
    );

    return response.data;
  }

  async update({ formData, tokenId }: { formData: AccessTokenParams; tokenId: string }) {
    const response = await this.apiClient.patch<ApiResponse<AccessToken, null>>(
      `/access/access-tokens/${tokenId}`,
      formData,
    );

    return response.data;
  }

  async delete(tokenId: string) {
    await this.apiClient.delete(`/access/access-tokens/${tokenId}`);
  }

  async getOwnAccessTokens() {
    const response = await this.apiClient.get<ApiResponse<AccessToken[], TotalListMeta>>(
      '/access/access-tokens/own',
    );

    return response.data;
  }
}

export default AccessTokens;
