import { ChangeEvent } from 'react';
import { FieldProps } from '@rjsf/utils';
import UploadAvatar from '@/components/UploadAvatar';
import Box from '@mui/material/Box';

export default function AvatarField(props: FieldProps) {
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event?.target?.files?.[0]);
  };

  const objectURL =
    props.formData instanceof File
      ? window.URL.createObjectURL(props.formData)
      : props.formData && props.formData + `?${Date.now()}`;

  return (
    <Box sx={{ width: 116, display: 'flex', alignSelf: 'center' }}>
      <UploadAvatar id="createUserAvatar" avatarUrl={objectURL} onChange={handleChange} />
    </Box>
  );
}
