export const EXCHANGE_RATE_PERMISSIONS_DICT = {
  LIST: {
    title: 'EXCHANGE_RATES_LIST',
    description: 'Controls list exchange rates use-case',
    resource: 'EXCHANGE_RATES',
    modifier: 'LIST',
  },
};

export const EXCHANGE_RATE_PERMISSIONS_LIST = Object.values(EXCHANGE_RATE_PERMISSIONS_DICT);
