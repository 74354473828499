export const AGGREGATION_TASK_PERMISSIONS_DICT = {
  GET: {
    title: 'AGGREGATION_TASKS_GET',
    description: 'Controls get aggregation task use-case',
    resource: 'AGGREGATION_TASKS',
    modifier: 'GET',
  },
};

export const AGGREGATION_TASK_PERMISSIONS_LIST = Object.values(AGGREGATION_TASK_PERMISSIONS_DICT);
