import { SyntheticEvent, useEffect, useState } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { UIErrorTypes } from '@/types/errors';
import { useAppDispatch } from '@/store/hooks';
import { clearErrorState } from '@/store/reducers/errors/errorsSlice';
import { useError } from '@/hooks/useError';

export default function Snackbar() {
  const [open, setOpen] = useState(false);
  const error = useError(UIErrorTypes.Snack);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    dispatch(clearErrorState());
  };

  return (
    <div>
      <MuiSnackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
          {error?.message}
        </Alert>
      </MuiSnackbar>
    </div>
  );
}
