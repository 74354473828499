import { AxiosInstance } from 'axios';

class Base {
  apiClient: AxiosInstance;

  constructor({ apiClient }: { apiClient: AxiosInstance }) {
    if (!apiClient) throw new Error('[apiClient] required');

    this.apiClient = apiClient;
  }
}

export default Base;
