import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ROUTES } from '@/constants/routes';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

type Props = {
  title: string;
  message: string;
  buttonText?: string;
  redirectRoute?: ROUTES;
};

const SuccessReset = ({
  title,
  message,
  buttonText = 'BACK TO LOGIN',
  redirectRoute = ROUTES.LOGIN,
}: Props) => (
  <Container>
    <Typography variant="h4">{title}</Typography>
    <Typography variant="subtitle1" color="secondary" textAlign="center">
      {message}
    </Typography>
    <Button to={redirectRoute} component={NavLink} variant="contained" fullWidth>
      {buttonText}
    </Button>
  </Container>
);

export default SuccessReset;
