import Base from './Base';
import { Role } from '@/types/role';
import { ApiResponse, ListParams, TotalListMeta } from '@/types/api';

export type RolesListParams = ListParams;

export type RolesModifyParams = {
  title: string;
  description?: string;
  rateLimit?: number;
  permissionIds: number[];
};

class Roles extends Base {
  list = async (params: RolesListParams) => {
    const response = await this.apiClient.get<ApiResponse<Role[], TotalListMeta>>('/access/roles', {
      params,
    });

    return response.data;
  };

  async get(roleId: number) {
    const response = await this.apiClient.get<ApiResponse<Role>>(`/access/roles/${roleId}`);

    return response.data;
  }

  async create(formData: RolesModifyParams) {
    const response = await this.apiClient.post<ApiResponse<Role, null>>('/access/roles', formData);

    return response.data;
  }

  async delete(roleId: number) {
    await this.apiClient.delete(`/access/roles/${roleId}`);
  }

  async update({ formData, roleId }: { formData: RolesModifyParams; roleId: number }) {
    const response = await this.apiClient.patch<ApiResponse<Role>>(
      `/access/roles/${roleId}`,
      formData,
    );

    return response.data;
  }
}

export default Roles;
