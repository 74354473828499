import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { HeaderMenu } from './HeaderMenu';
import { ROUTES } from '@/constants/routes';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.backgroundHeader.main,
  height: 64,
  padding: theme.spacing(1, 2, 1, 2),
  zIndex: 1201,
}));

const Header = ({ isMenu = true }: { isMenu?: boolean }) => {
  return (
    <HeaderContainer>
      <Link href={ROUTES.PROFILE_OWN}>
        <img src="/logo.svg" alt="" width="139" height="46" />
      </Link>
      {isMenu ? <HeaderMenu /> : null}
    </HeaderContainer>
  );
};

export default Header;
