import Typography from '@mui/material/Typography';
import { type ErrorModalProps } from './ErrorModalProps';
import BaseModal from '../BaseModal';
import { useAppDispatch } from '@/store/hooks';
import { closeModal } from '@/store/reducers/modals/modalsSlice';
import { ModalType } from '@/types/modals';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

export const ErrorModal = ({ title, message }: ErrorModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal({ type: ModalType.ErrorModal }));
  };

  const renderFooter = () => {
    return (
      <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
        <Button variant="contained" onClick={handleCloseModal} fullWidth>
          OK
        </Button>
      </DialogActions>
    );
  };

  return (
    <BaseModal open onClose={handleCloseModal} renderFooterCustom={renderFooter} maxWidth="xs">
      <Container>
        <ErrorIcon sx={{ width: 50, height: 50 }} color="error" />
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{message}</Typography>
      </Container>
    </BaseModal>
  );
};
