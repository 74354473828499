import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { RequestPasswordResetForm } from '@/components/forms/RequestPasswordResetForm';
import SuccessBlock from '@/components/SuccessBlock';

export default function RequestPasswordResetPage() {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" height="100%">
      <Grid item xs={11} md={6} lg={4}>
        {isSuccess ? (
          <SuccessBlock
            title="Request Password Reset"
            message="A reset link has been sent to your email address. Please check your inbox."
          />
        ) : (
          <RequestPasswordResetForm onSuccess={() => setIsSuccess(true)} />
        )}
      </Grid>
    </Grid>
  );
}
