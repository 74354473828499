import { useCallback, useMemo, useState } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import { useAbility } from '@casl/react';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table, { TableState } from '@/components/table/Table';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectPermissions,
  selectPermissionsListIsLoading,
  selectPermissionsListMeta,
} from '@/store/reducers/permissions/permissionsSlice';
import { Permission } from '@/types/permission';
import {
  createPermission,
  deletePermission,
  listPermissions,
  updatePermission,
} from '@/store/reducers/permissions/actions';
import { ListParams } from '@/types/api';
import { useModal } from '@/hooks/useModal';
import { ModalType } from '@/types/modals';
import { AbilityContext } from '@/contexts/accessControlContext';
import Add from '@mui/icons-material/Add';
import { PermissionsModifyParams } from '@/api/Permissions';
import { PERMISSIONS_DICT } from '@/constants/permissions';

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function PermissionsPage() {
  const permissions = useAppSelector(selectPermissions);
  const permissionsListMeta = useAppSelector(selectPermissionsListMeta);
  const isLoading = useAppSelector(selectPermissionsListIsLoading);
  const ability = useAbility(AbilityContext);
  const dispatch = useAppDispatch();
  const [paramsState, setParamsState] = useState<ListParams>({});

  const { openModal: openPermissionModal } = useModal(ModalType.PermissionModal);
  const { openModal: openSuccessModal } = useModal(ModalType.SuccessModal);
  const { openModal: openConfirmationModal } = useModal(ModalType.ConfirmationModal);

  const handleOpenCreatePermission = useCallback(() => {
    openPermissionModal({
      title: 'Create Permission',
      buttonText: 'CREATE PERMISSION',
      onSubmit: async (formData: PermissionsModifyParams) => {
        await dispatch(createPermission(formData)).unwrap();

        openSuccessModal({
          title: 'Permission Successfully Created',
          buttonText: 'OK',
        });

        dispatch(listPermissions(paramsState));
      },
    });
  }, [paramsState]);

  const handleEditPermission = useCallback((item: Permission) => {
    openPermissionModal({
      title: 'Edit Permission',
      buttonText: 'UPDATE PERMISSION',
      formData: Object.fromEntries(Object.entries(item).filter(([_, v]) => v !== null)),
      onSubmit: async (formData: PermissionsModifyParams) => {
        await dispatch(updatePermission({ formData, permissionId: item.id })).unwrap();

        openSuccessModal({
          title: 'Permission Successfully Updated',
          buttonText: 'OK',
        });
      },
    });
  }, []);

  const handleDeletePermissions = useCallback(
    (permissionId: number) => {
      openConfirmationModal({
        title: 'Delete Permission',
        buttonText: 'DELETE',
        message: 'Are you sure you want to delete the permission?',
        onSubmit: async () => {
          await dispatch(deletePermission(permissionId)).unwrap();
        },
      });
    },
    [dispatch, openConfirmationModal],
  );

  const handleOnChange = useCallback((state: TableState) => {
    const filters = state.filters.reduce<Record<string, string>>((acc, curr: any) => {
      acc[curr.id] = curr.value;

      return acc;
    }, {});

    const params: ListParams = {
      ...filters,
      limit: state.pagination.pageSize,
      offset: state.pagination.pageSize * state.pagination.pageIndex,
      sortBy: state.sorting?.[0]?.id,
      orderBy: state.sorting?.[0]?.desc ? 'DESC' : 'ASC',
      search: state.searchFilter || null,
    };

    setParamsState(params);

    dispatch(listPermissions(params));
  }, []);

  const columns = useMemo<MRT_ColumnDef<Permission>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        size: 40,
        grow: 3,
        enableColumnFilter: false,
        Cell: ({ row }) => <Typography noWrap>{row.original.title}</Typography>,
      },
      {
        accessorKey: 'resource',
        header: 'Resource',
        grow: 3,
        size: 40,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'modifier',
        header: 'Modifier',
        grow: 3,
        size: 40,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 40,
        grow: 10,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const rowActions = useMemo(
    () => [
      {
        name: 'Edit',
        action: (item: Permission) => handleEditPermission(item),
        icon: <Edit />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.PERMISSIONS.UPDATE.modifier,
          PERMISSIONS_DICT.ACCESS.PERMISSIONS.UPDATE.resource,
        ),
      },
      {
        name: 'Delete',
        action: (item: Permission) => handleDeletePermissions(item.id),
        icon: <Delete />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.PERMISSIONS.DELETE.modifier,
          PERMISSIONS_DICT.ACCESS.PERMISSIONS.DELETE.resource,
        ),
      },
    ],
    [handleDeletePermissions, handleEditPermission, ability],
  );

  const toolbarActions = useMemo(
    () => [
      {
        name: 'NEW PERMISSION',
        action: handleOpenCreatePermission,
        icon: <Add />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.PERMISSIONS.CREATE.modifier,
          PERMISSIONS_DICT.ACCESS.PERMISSIONS.CREATE.resource,
        ),
      },
    ],
    [handleOpenCreatePermission, ability],
  );

  const options = useMemo(() => {
    return {
      data: permissions,
      columns: columns,
      rowCount: permissionsListMeta?.totalCount || 100,
      layoutMode: 'grid-no-grow' as const,
      enableColumnFilters: false,
    };
  }, [permissions, columns, permissionsListMeta?.totalCount]);

  return (
    <Box>
      <Title variant="h4">Permission Management</Title>
      <Table
        options={options}
        onChange={handleOnChange}
        rowActions={rowActions}
        toolbarActions={toolbarActions}
        isLoading={isLoading}
      />
    </Box>
  );
}
