export const WS_API_PERMISSIONS_DICT = {
  CONNECT: {
    title: 'WS_API_CONNECT',
    description: 'Controls connection to the Websocket Gateway',
    resource: 'WS_API',
    modifier: 'CONNECT',
  },
  AGGREGATE_OHLCV_EMITTER: {
    title: 'WS_API_AGGREGATE_OHLCV_EMITTER',
    description: 'Controls connection to the AggregateOHLCVEmitter',
    resource: 'WS_API',
    modifier: 'AGGREGATE_OHLCV_EMITTER',
  },
  PRICING_EMITTER: {
    title: 'WS_API_PRICING_EMITTER',
    description: 'Controls connection to the PricingEmitter',
    resource: 'WS_API',
    modifier: 'PRICING_EMITTER',
  },
};

export const WS_API_PERMISSIONS_LIST = Object.values(WS_API_PERMISSIONS_DICT);
