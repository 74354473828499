import Providers from '@/providers';
import RouterProvider from '@/providers/RouterProvider';
import './App.css';

function App() {
  return (
    <Providers>
      <RouterProvider />
    </Providers>
  );
}

export default App;
