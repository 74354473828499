export const ACCESS_TOKEN_PERMISSIONS_DICT = {
  CREATE: {
    title: 'ACCESS_TOKENS_CREATE',
    description: 'Controls create access token use-case',
    resource: 'ACCESS_TOKENS',
    modifier: 'CREATE',
  },
  DELETE: {
    title: 'ACCESS_TOKENS_DELETE',
    description: 'Controls delete access token use-case',
    resource: 'ACCESS_TOKENS',
    modifier: 'DELETE',
  },
  GET: {
    title: 'ACCESS_TOKENS_GET',
    description: 'Controls get access token use-case',
    resource: 'ACCESS_TOKENS',
    modifier: 'GET',
  },
  LIST: {
    title: 'ACCESS_TOKENS_LIST',
    description: 'Controls list access tokens use-case',
    resource: 'ACCESS_TOKENS',
    modifier: 'LIST',
  },
  UPDATE: {
    title: 'ACCESS_TOKENS_UPDATE',
    description: 'Controls update access token use-case',
    resource: 'ACCESS_TOKENS',
    modifier: 'UPDATE',
  },
};

export const ACCESS_TOKEN_PERMISSIONS_LIST = Object.values(ACCESS_TOKEN_PERMISSIONS_DICT);
