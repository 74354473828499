import { getErrorMessage, getFieldErrorMessages } from '@/constants/errors/messages';
import { AxiosError } from 'axios';
import { ApiException } from '../exceptions/ApiException';
import { EXCEPTION_CODES, EXCEPTION_FIELD_CODES } from '@/constants/errors/codes';

export type ErrorResponse = {
  error: {
    code: EXCEPTION_CODES;
    fields: Record<string, EXCEPTION_FIELD_CODES>;
    context: object;
  };
};

export default function errorFactory(error: AxiosError<ErrorResponse>) {
  const code = error?.response?.data.error.code;
  const context = error?.response?.data.error.context;
  const fields = error?.response?.data.error.fields;

  const message = getErrorMessage(code);
  const fieldMessages = getFieldErrorMessages(fields);

  return new ApiException(message, code, fieldMessages, context);
}
