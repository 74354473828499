import { ReactNode } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider as Provider } from '@mui/x-date-pickers/LocalizationProvider';

type Props = {
  children: ReactNode;
};

const LocalizationProvider = ({ children }: Props) => {
  return <Provider dateAdapter={AdapterDayjs}>{children}</Provider>;
};

export default LocalizationProvider;
