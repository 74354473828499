import { LoaderFunctionArgs, defer, redirect } from 'react-router';
import { EXCEPTION_CODES } from '@/constants/errors/codes';
import { ROUTES } from '@/constants/routes';
import { store } from '@/store/store';
import { setCSRFToken, setIsLoggedIn } from '@/store/reducers/session/sessionSlice';
import { localStorage } from '@/utils/storage';
import { ApiException } from '@/api/exceptions/ApiException';

export default abstract class BaseLoader<T> {
  abstract loader(args: LoaderFunctionArgs): Promise<T>;

  createLoader() {
    return (args: LoaderFunctionArgs) => {
      return defer({ promise: this.executeLoader(args) });
    };
  }

  async executeLoader(args: LoaderFunctionArgs) {
    try {
      const isLoggedIn = this.checkIsLoggedIn();

      if (isLoggedIn) {
        return await this.loader(args);
      }

      return Promise.resolve(null);
    } catch (error) {
      return this.handleError(error);
    }
  }

  checkIsLoggedIn() {
    const csrfToken = localStorage.get('csrfToken');

    const isLoggedIn = Boolean(csrfToken);

    store.dispatch(setIsLoggedIn(isLoggedIn));
    store.dispatch(setCSRFToken(csrfToken));

    return isLoggedIn;
  }

  handleError(error: unknown) {
    if (error instanceof ApiException && error.code === EXCEPTION_CODES.NOT_AUTHENTICATED) {
      return redirect(ROUTES.LOGIN);
    }

    throw error;
  }
}
