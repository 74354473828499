import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { acceptAccessRequest, listAccessRequests, rejectAccessRequest } from './actions';
import { TotalListMeta } from '@/types/api';
import { AccessRequest } from '@/types/accessRequest';

type InitialStateSessionProps = {
  accessRequests: AccessRequest[];
  meta: TotalListMeta | null;
  isLoading: boolean;
};

const initialState: InitialStateSessionProps = {
  accessRequests: [],
  meta: null,
  isLoading: false,
};

export const accessRequestsSlice = createSlice({
  name: 'accessRequests',
  initialState,
  reducers: {
    clearUsers: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listAccessRequests.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listAccessRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accessRequests = action.payload.accessRequests;
      state.meta = action.payload.meta;
    });

    builder.addMatcher(
      isAnyOf(acceptAccessRequest.fulfilled, rejectAccessRequest.fulfilled),
      (state, action) => {
        state.accessRequests = state.accessRequests.filter(
          (item) => item.id !== action.meta.arg.requestId,
        );
      },
    );
  },
  selectors: {
    selectAccessRequests: (state) => state.accessRequests,
    selectAccessRequestsListMeta: (state) => state.meta,
    selectAccessRequestsListIsLoading: (state) => state.isLoading,
  },
});

export const { clearUsers } = accessRequestsSlice.actions;
export const {
  selectAccessRequests,
  selectAccessRequestsListMeta,
  selectAccessRequestsListIsLoading,
} = accessRequestsSlice.selectors;
