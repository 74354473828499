import Typography from '@mui/material/Typography';
import { type ConfirmationModalProps } from './ConfirmationModalProps';
import BaseModal from '../BaseModal';
import { useAppDispatch } from '@/store/hooks';
import { closeModal } from '@/store/reducers/modals/modalsSlice';
import { ModalType } from '@/types/modals';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

export const ConfirmationModal = ({
  title,
  message,
  buttonText,
  onSubmit,
}: ConfirmationModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal({ type: ModalType.ConfirmationModal }));
  };

  const handleSubmit = async () => {
    await onSubmit();

    dispatch(closeModal({ type: ModalType.ConfirmationModal }));
  };

  return (
    <BaseModal
      open
      onClose={handleCloseModal}
      submitButtonText={buttonText}
      onSubmit={handleSubmit}
      maxWidth="xs"
    >
      <Container>
        <ReportProblemIcon sx={{ width: 50, height: 50 }} color="warning" />
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{message}</Typography>
      </Container>
    </BaseModal>
  );
};
