import { LoginForm } from '@/components/forms/LoginForm';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function LoginPage() {
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={11} md={6} lg={4}>
          <LoginForm />
        </Grid>
      </Grid>
    </Box>
  );
}
