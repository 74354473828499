import { useCallback, useMemo, useState } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import { useAbility } from '@casl/react';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table, { TableState } from '@/components/table/Table';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectRoles,
  selectRolesListIsLoading,
  selectRolesListMeta,
} from '@/store/reducers/roles/rolesSlice';
import { Role } from '@/types/role';
import { createRole, deleteRole, listRoles, updateRole } from '@/store/reducers/roles/actions';
import { ListParams } from '@/types/api';
import { useModal } from '@/hooks/useModal';
import { ModalType } from '@/types/modals';
import { AbilityContext } from '@/contexts/accessControlContext';
import { formatNumber } from '@/utils/numeral';
import Add from '@mui/icons-material/Add';
import { RolesModifyParams } from '@/api/Roles';
import { PERMISSIONS_DICT } from '@/constants/permissions';

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function RolesPage() {
  const roles = useAppSelector(selectRoles);
  const rolesListMeta = useAppSelector(selectRolesListMeta);
  const isLoading = useAppSelector(selectRolesListIsLoading);
  const ability = useAbility(AbilityContext);
  const dispatch = useAppDispatch();
  const [paramsState, setParamsState] = useState<ListParams>({});

  const { openModal: openRoleModal } = useModal(ModalType.RoleModal);
  const { openModal: openSuccessModal } = useModal(ModalType.SuccessModal);
  const { openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal(
    ModalType.ConfirmationModal,
  );

  const handleOpenCreateRole = useCallback(() => {
    openRoleModal({
      title: 'Create Role',
      buttonText: 'CREATE ROLE',
      onSubmit: async (formData: RolesModifyParams) => {
        await dispatch(createRole(formData)).unwrap();

        openSuccessModal({
          title: 'Role Successfully Created',
          buttonText: 'OK',
        });

        dispatch(listRoles(paramsState));
      },
    });
  }, [paramsState]);

  const handleEditRole = useCallback((item: Role) => {
    openRoleModal({
      title: 'Edit Role',
      buttonText: 'UPDATE ROLE',
      formData: item,
      onSubmit: async (formData: RolesModifyParams) => {
        await dispatch(updateRole({ formData, roleId: item.id })).unwrap();

        openSuccessModal({
          title: 'Role Successfully Updated',
          buttonText: 'OK',
        });
      },
    });
  }, []);

  const handleDeleteRoles = useCallback(
    (roleId: number) => {
      openConfirmationModal({
        title: 'Delete Role',
        buttonText: 'DELETE',
        message: 'Are you sure you want to delete the role?',
        onSubmit: async () => {
          try {
            await dispatch(deleteRole(roleId)).unwrap();
          } catch (error) {
            closeConfirmationModal();
          }
        },
      });
    },
    [dispatch, openConfirmationModal, closeConfirmationModal],
  );

  const handleOnChange = useCallback((state: TableState) => {
    const filters = state.filters.reduce<Record<string, string>>((acc, curr: any) => {
      acc[curr.id] = curr.value;

      return acc;
    }, {});

    const params: ListParams = {
      ...filters,
      limit: state.pagination.pageSize,
      offset: state.pagination.pageSize * state.pagination.pageIndex,
      sortBy: state.sorting?.[0]?.id,
      orderBy: state.sorting?.[0]?.desc ? 'DESC' : 'ASC',
      search: state.searchFilter || null,
    };

    setParamsState(params);

    dispatch(listRoles(params));
  }, []);

  const columns = useMemo<MRT_ColumnDef<Role>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        grow: 1,
        size: 120,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'rateLimit',
        header: 'Rate Limit',
        grow: 1,
        size: 80,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Typography>
            {row.original.rateLimit ? formatNumber(row.original.rateLimit) : 'Unlimited'}
          </Typography>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
        grow: 10,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const rowActions = useMemo(
    () => [
      {
        name: 'Edit',
        action: (item: Role) => handleEditRole(item),
        icon: <Edit />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ROLES.UPDATE.modifier,
          PERMISSIONS_DICT.ACCESS.ROLES.UPDATE.resource,
        ),
      },
      {
        name: 'Delete',
        action: (item: Role) => handleDeleteRoles(item.id),
        icon: <Delete />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ROLES.DELETE.modifier,
          PERMISSIONS_DICT.ACCESS.ROLES.DELETE.resource,
        ),
      },
    ],
    [handleDeleteRoles, handleEditRole, ability],
  );

  const toolbarActions = useMemo(
    () => [
      {
        name: 'NEW ROLE',
        action: handleOpenCreateRole,
        icon: <Add />,
        can: ability.can(
          PERMISSIONS_DICT.ACCESS.ROLES.CREATE.modifier,
          PERMISSIONS_DICT.ACCESS.ROLES.CREATE.resource,
        ),
      },
    ],
    [handleOpenCreateRole, ability],
  );

  const options = useMemo(() => {
    return {
      data: roles,
      columns: columns,
      rowCount: rolesListMeta?.totalCount || 100,
      layoutMode: 'grid-no-grow' as const,
      enableColumnFilters: false,
    };
  }, [roles, columns, rolesListMeta?.totalCount]);

  return (
    <Box>
      <Title variant="h4">Role Management</Title>
      <Table
        options={options}
        onChange={handleOnChange}
        rowActions={rowActions}
        toolbarActions={toolbarActions}
        isLoading={isLoading}
      />
    </Box>
  );
}
