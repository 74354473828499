import { ReactNode } from 'react';
import { defineAbility } from '@casl/ability';
import { AbilityContext } from '@/contexts/accessControlContext';
import { selectRole } from '@/store/reducers/session/sessionSlice';
import { useAppSelector } from '@/store/hooks';
import { Permission } from '@/types/permission';

export const getAbility = (permissions: Permission[]) => {
  return defineAbility((can) => {
    permissions.forEach(({ modifier, resource }) => {
      can(modifier, resource);
    });
  });
};

type Props = {
  children: ReactNode;
};

const AccessControlProvider = ({ children }: Props) => {
  const role = useAppSelector(selectRole);
  const ability = getAbility(role?.permissions || []);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export default AccessControlProvider;
