import { ProfileMeta, User } from '@/types/user';
import { ApiResponse } from '@/types/api';
import Base from './Base';

class Profile extends Base {
  get = async () => {
    const response = await this.apiClient.get<ApiResponse<User, ProfileMeta>>('/access/profile');

    return response.data;
  };

  async update({ formData }: { formData: FormData }) {
    const response = await this.apiClient.patchForm<ApiResponse<User>>('/access/profile', formData);

    return response.data;
  }
}

export default Profile;
